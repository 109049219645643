import React, { useContext, useEffect, useState } from 'react';

import { Auth, Storage } from 'aws-amplify';
import { Link, navigate } from 'gatsby';

import { AuthContext } from '@/api/auth';
import { getMember, getMemberContractByMemberId } from '@/api/mutation';
import PrimaryOutlinedButton from '@/common/buttons/PrimaryOutlinedButton';
import ModalBase from '@/common/modals/ModalBase';
import ModalMakePayment from '@/common/payment/modalMakePayment';
import { toastSuccess } from '@/common/toasts/toast';
import { useApolloClient, useLazyQuery } from '@apollo/client';
import { IContract } from '@/interfaces/Contract.interface';
import {
  IContractWithStatus,
  useMemberShipContext,
} from '@/context/MembershipContext';

function Sidebar() {
  // const [chargeSinglePayment] = useMutation(chargeSinglePaymentViaVivaPay);
  const [outStandingModalPay, setOutStandingModalPay] = useState(false);
  const sidebarMenu = [
    { name: 'dashboard', link: 'dashboard', id: '' },
    // { name: 'my bookings', link: 'bookings', id: 'bookings' },
    // { name: 'timetable', link: 'timetable', id: 'timetable' },
    {
      name: 'Classes',
      link: '/dashboard/class/timetable',
      id: '',
      tabs: [
        { name: 'Timetable', link: 'dashboard/class/timetable' },
        { name: 'Bookings', link: 'dashboard/class/bookings' },
      ],
    },
    { name: 'my membership', link: 'memberships', id: 'memberships' },
    {
      name: 'my profile',
      link: 'account',
      id: 'account',
      tabs: [
        { name: 'General', link: 'dashboard/account' },
        { name: 'Password', link: 'dashboard/account/password' },
        { name: 'Biling', link: 'dashboard/account/biling' },
        { name: 'Notifications', link: 'dashboard/account/notifications' },
      ],
    },
    // { name: 'upgrade', link: 'upgrade', id: 'upgrade' },
    {
      name: 'member benefits',
      link: 'benefits',
      id: 'benefits',
      isNotReady: true,
    },
    {
      name: 'Club Search',
      link: 'club-search',
      id: 'club-search',
      isNotReady: true,
    },
    // { name: 'log out', link: '', id: 'logout' },
  ];
  const [classExpand, setExpandClass] = useState<boolean>(false);
  const [getUserMembership] = useLazyQuery(getMemberContractByMemberId);
  // const [userInfoMemberShip, setUserInfoMemberShip] = useState();
  const { userInfoMemberShip, setUserInfoMemberShip, setUserInfo } =
    React.useContext(AuthContext);
  const mountedFunction = () => {
    setfetching(true);
    try {
      Auth.currentAuthenticatedUser()
        .then((user) => {
          client
            .query({
              query: getMember,
              fetchPolicy: 'no-cache',
              variables: {
                memberId: user.attributes['custom:memberId'],
              },
            })
            .then(async (res) => {
              const data = res.data.getMember;
              const imgUrl = data?.imageUrl
                ? await Storage.get(data.imageUrl)
                : '/icons/Dashboard/profile.svg';
              setUserInfo({ ...data, imageUrl: imgUrl });
              localStorage.setItem(
                'currentUserDetail',
                JSON.stringify(res.data.getMember)
              );
            });
          getUserMembership({
            fetchPolicy: 'no-cache',
            variables: {
              memberId: user.attributes['custom:memberId'],
            },
          })
            .then((membership) => {
              setUserInfoMemberShip(
                membership.data.getMemberContractByMemberId.items.filter(
                  (i: IContract) => {
                    if (i.expiryDateTime != null) {
                      if (
                        new Date(i.expiryDateTime).valueOf() <
                        new Date().valueOf()
                      ) {
                        return false;
                      }
                    }

                    if (i.endDateTime != null) {
                      if (
                        new Date(i.endDateTime).valueOf() < new Date().valueOf()
                      ) {
                        return false;
                      }
                    }

                    return true;
                  }
                )
              );
            })
            .catch((error) => {
              console.log(error);
              setfetching(true);
            })
            .finally(() => {
              setfetching(false);
            });
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (e) {
      console.log(e);
      navigate('/');
    }
  };
  useEffect(() => {
    if (!userInfoMemberShip) {
      mountedFunction();
    }
  }, []);
  const client = useApolloClient();

  const refresh = useContext(AuthContext).refreshData;

  useEffect(() => {
    if (refresh) {
      mountedFunction();
    }
  }, [refresh]);
  const {
    membershipInfo,
    getUserMembershipInfo,
    userSelectedMembership,
    setUserSelectedMembership,
  } = useMemberShipContext();
  useEffect(() => {
    if (!userSelectedMembership) getUserMembershipInfo();
  }, []);
  const Init = React.useContext(AuthContext).Init;

  const userInfo = React.useContext(AuthContext).userInfo;

  const [modal, setModal] = useState(false);

  const membershipStatus = React.useMemo(() => {
    const result: Record<string, any> = {
      status: null,
      color: '',
      title: '',
    };
    return result;
  }, [userInfoMemberShip]);

  const [allMembershipStatus, setAllMembershipStatus] = useState({
    active: 0,
    suspended: 0,
    pendingSuspension: 0,
    cancelled: 0,
    pendingCancellation: 0,
    future: 0,
    remainingDay: 0,
  });
  useEffect(() => {
    const result = membershipInfo.reduce(
      (total: Record<string, number>, current: IContractWithStatus) => {
        if (current.status === 'active')
          return { ...total, active: total.active + 1 };
        if (current.status === 'suspended')
          return { ...total, suspended: total.suspended + 1 };
        if (current.status === 'pendingSuspension')
          return { ...total, pendingSuspension: total.pendingSuspension + 1 };
        if (current.status === 'pendingCancellation')
          return {
            ...total,
            pendingCancellation: total.pendingCancellation + 1,
          };
        if (current.status === 'future')
          return { ...total, future: total.future + 1 };
        if (current.status === 'cancelled')
          return { ...total, cancelled: total.cancelled + 1 };
      },
      {
        active: 0,
        suspended: 0,
        pendingSuspension: 0,
        cancelled: 0,
        pendingCancellation: 0,
        future: 0,
        remainingDay: 0,
      }
    );
    setAllMembershipStatus(result);
  }, [membershipInfo]);

  const openInNewTab = (url: string) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const [fetching, setfetching] = useState(false);

  const membershipSummary = () => {
    return (
      <>
        {/* membership active */}
        {allMembershipStatus.active > 0 && (
          <div
            className={`flex items-center justify-center w-max px-3 py-1 rounded-[2px] h-[18px] text-white text-[10px] text-center font-bold uppercase bg-primary`}
          >
            {/* {membershipStatus.title} */}
            {allMembershipStatus.active} membership active
          </div>
        )}
        {/* membership pending suspension */}
        {allMembershipStatus.pendingSuspension > 0 && (
          <div
            className={`flex items-center justify-center w-max px-3 my-2 py-1 rounded-[2px] h-[18px] text-white text-[10px] text-center font-bold uppercase bg-[#FF6900]`}
          >
            {/* {membershipStatus.title} */}
            {allMembershipStatus.pendingSuspension} pending suspension
          </div>
        )}
        {/* membership suspended */}
        {allMembershipStatus.suspended > 0 && (
          <div
            className={`flex items-center justify-center w-max my-2 px-3 py-1 rounded-[2px] h-[18px] text-white text-[10px] text-center font-bold uppercase bg-[#FF6900]`}
          >
            {/* {membershipStatus.title} */}
            {allMembershipStatus.suspended} suspended
          </div>
        )}
        {/* membership pending cancelation */}
        {allMembershipStatus.pendingCancellation > 0 && (
          <div
            className={`flex items-center justify-center w-max my-2 px-3 py-1 rounded-[2px] h-[18px] text-white text-[10px] text-center font-bold uppercase bg-red-500`}
          >
            {/* {membershipStatus.title} */}
            {allMembershipStatus.pendingCancellation} pending cancelation
          </div>
        )}
        {/* membership cancelled */}
        {allMembershipStatus.future > 0 && (
          <div
            className={`flex items-center justify-center w-max my-2 px-3 py-1 rounded-[2px] h-[18px] text-black text-[10px] text-center font-bold uppercase bg-[#fbff4b]`}
          >
            {/* {membershipStatus.title} */}
            {allMembershipStatus.future} future
          </div>
        )}
      </>
    );
  };

  return (
    <>
      {outStandingModalPay && (
        <ModalMakePayment
          changeMembershipData={{
            newMemberShipId: '',
            changeMembershipType: '',
            locationId: userInfoMemberShip[0]?.locationId
              ? userInfoMemberShip[0]?.locationId
              : userInfo?.homeLocationId,
          }}
          setModalMakePayment={setOutStandingModalPay}
          amount={userInfo?.outstandingBalance}
          paymentType="outstanding"
          openModalOutside={setOutStandingModalPay}
          setStep={() => {
            toastSuccess('Thank you for paying outstanding balance');
            setUserInfo({ ...userInfo, outstandingBalance: 0 });
          }}
        />
      )}

      <div className="right-[26px] pl-[20px] rounded-l-full backdrop-blur-md top-[36px] absolute flex sm:hidden gap-[20px] items-center z-30">
        <div
          className="cursor-pointer"
          onClick={() => navigate('/dashboard/account')}
        >
          <img
            className="rounded-full border border-white max-w-[40px] max-h-[40px] w-[40px] h-[40px]"
            src={userInfo?.imageUrl}
            alt="user avatar"
          />
        </div>
      </div>
      <div className="w-[339px] h-[calc(100vh-40px)] bg-[#13181b] border-r border-gray sm:hidden">
        <div
          className="ml-[24px] mt-11 mb-6 w-[269px] h-[26px] cursor-pointer"
          onClick={() => openInNewTab('https://www.plusfitness.com.au/')}
        >
          <img
            className="block sm:hidden mx-auto w-full h-full object-contain"
            src="/icons/pf-logo-line.svg"
          />
        </div>
        {fetching ? (
          ''
        ) : (
          <div className="ml-[24px] mb-5">
            <p className="text-xs text-[#848484] leading-[19px]">Member name</p>
            <p className=" text-white font-bold text-[25px] leading-[25px] font-bicyclette">
              {userInfo?.surname && userInfo?.givenName
                ? userInfo?.givenName + ' ' + userInfo?.surname
                : 'name'}
            </p>
          </div>
        )}
        <div className="mb-[30px] ml-[26px] ">
          {fetching ? (
            <>
              <div className=" relative " data-title="dot-pulse">
                <div className="stage w-[200px]">
                  <div className="dot-pulse"></div>
                </div>
              </div>
            </>
          ) : (
            <>
              {' '}
              {userInfo?.isBlocked && (
                <div
                  className={`flex uppercase items-center mb-2 justify-center w-max px-3 py-1 rounded-[2px] h-[18px] text-white text-[10px] text-center font-bold bg-textError`}
                >
                  YOUR MEMBER CARD IS BEING BLOCKED
                </div>
              )}
              {!userInfo?.isBlocked && membershipSummary()}
              {userInfo?.outstandingBalance &&
              userInfo?.outstandingBalance !== 0 ? (
                <div
                  onClick={() => {
                    setOutStandingModalPay(true);
                  }}
                  className={`flex cursor-pointer uppercase mt-2 items-center mb-2 justify-center w-max px-3 py-1 rounded-[2px] h-[18px] text-white text-[10px] text-center font-bold  bg-textError`}
                >
                  outstanding:${userInfo?.outstandingBalance}
                </div>
              ) : null}
              {allMembershipStatus?.remainingDay > 0 && (
                <p className="text-textSecondary text-left text-[10px] font-bold uppercase mt-2">
                  {allMembershipStatus?.remainingDay} DAYS REMAINING
                </p>
              )}
            </>
          )}
          <div className="mt-[20px]">
            <p className="text-xs text-[#848484] leading-[19px]">Home Club</p>
            <p className=" text-white font-bold text-[25px] leading-[25px] font-bicyclette">
              {userInfo?.homeLocationDetails?.name}
            </p>
          </div>
        </div>

        <div className="flex flex-col h-[calc(100vh-350px)] overflow-auto hiddenScrollWidth ">
          {sidebarMenu.map((item: any, index: number) => (
            <div key={index} className="">
              {item.name == 'Classes' ? (
                <div>
                  <div
                    onClick={() => {
                      if (
                        userInfoMemberShip?.length > 0 &&
                        membershipStatus?.status !== 'future' &&
                        !userInfo?.outstandingBalance
                      ) {
                        setExpandClass(true);
                      }

                      // navigate(item.link);
                    }}
                    key={index}
                    className={`cursor-pointer tracking-[1.4px] border-b border-[rgba(255,255,255,0.4)] pl-[24px] flex items-center h-[43px] text-sm font-medium leading-[19.6px] uppercase
                      ${
                        window.location.pathname.split('/').includes('class')
                          ? 'border-r-[5px] border-r-primary bg-[#292929] text-white'
                          : userInfoMemberShip?.length > 0 &&
                            membershipStatus?.status !== 'future' &&
                            !userInfo?.outstandingBalance
                          ? 'hover:bg-[#1F2528] text-white'
                          : 'text-[#595d5f]'
                      }
                      `}
                  >
                    {item.name}
                  </div>
                  <div
                    className={`overflow-hidden ${
                      classExpand
                        ? 'h-auto opacity-100  transition-all'
                        : 'h-0 opacity-0 transition-all'
                    }`}
                  >
                    {item.tabs.map((item: any, index: number) => (
                      <Link to={`/${item.link}`} key={index}>
                        <p
                          className={`h-[40px]  flex items-center ${
                            window.location.pathname == '/' + item.link
                              ? 'bg-[#292929] font-bold '
                              : 'font-normal'
                          } pl-[44px] capitalize border-b border-[rgba(255,255,255,0.4)]  text-white  text-sm flex items-center`}
                        >
                          {item.name}
                        </p>
                      </Link>
                    ))}
                  </div>
                </div>
              ) : (
                <Link
                  key={index}
                  to={(item.id === 'account'
                    ? '/dashboard/account'
                    : item.id === ''
                    ? '/dashboard'
                    : userInfoMemberShip?.length > 0 &&
                      `${item.isNotReady ? '' : '/dashboard'}${
                        index != 0 && !item.isNotReady ? '/' : ''
                      }${item.isNotReady ? '' : item.id}`
                  ).toString()}
                >
                  <div
                    key={index}
                    className={` h-[43px] cursor-pointer border-b border-[rgba(255,255,255,0.4)] pl-6 font-medium text-sm flex items-center tracking-[0.1em] uppercase 
                    ${index === 0 ? 'border-y' : ''} ${
                      window.location.pathname.split('/')[
                        window.location.pathname.split('/').length - 1
                      ] === item.link
                        ? 'border-r-[5px] border-r-primary bg-[#292929] text-white'
                        : item.id === 'account'
                        ? 'text-white hover:bg-[#1F2528] '
                        : userInfoMemberShip?.length > 0
                        ? 'text-white hover:bg-[#1F2528] '
                        : (window.location.pathname.split('/')[
                            window.location.pathname.split('/').length - 1
                          ] === 'dashboard' &&
                            window.location.pathname.split('/')[
                              window.location.pathname.split('/').length - 1
                            ] === item.link) ||
                          item.name === 'dashboard'
                        ? ' text-white hover:bg-[#1F2528] '
                        : 'text-[#595d5f] '
                    } ${
                      item.isNotReady
                        ? 'grid grid-cols-3  pr-[19px] items-center'
                        : ''
                    }`}
                  >
                    <div
                      className={` font-medium text-sm tracking-[1.4px] ${
                        item.isNotReady ? 'col-span-2' : ''
                      }`}
                    >
                      {item.name}
                    </div>
                    {item.isNotReady && (
                      <p className="text-center w-[84px] h-[14px] flex items-center justify-center text-[#848484] text-[8px] border border-[#848484] rounded-[4px]">
                        Coming Soon!
                      </p>
                    )}
                  </div>
                </Link>
              )}
            </div>
          ))}

          <div
            onClick={() => setModal(true)}
            className={`cursor-pointer h-[46px]  tracking-[1.4px] font-medium text-sm mt-auto text-white hover:bg-[#1F2528] flex justify-self-end border-t justify-between items-center  border-b border-[rgba(255,255,255,0.4)] pl-[26px] pt-[12px] pb-[12px] leading-[19.6px] uppercase                   `}
          >
            LOG OUT
            {/* <img className="w-6 h-6 mr-[17px]" src={logoutIcon} /> */}
            <svg
              className="w-6 h-6 mr-[17px]"
              width="22"
              height="20"
              viewBox="0 0 22 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.1429 5.71429V2.5C12.1429 1.67157 11.4713 1 10.6429 1H2.5C1.67157 1 1 1.67157 1 2.5V17.5C1 18.3284 1.67157 19 2.5 19H10.6429C11.4713 19 12.1429 18.3284 12.1429 17.5V13.4286"
                stroke="white"
              />
              <path
                d="M16.5645 5.51074L20.7148 9.66112L16.5645 13.8115"
                stroke="white"
              />
              <path d="M20.7143 9.57129L7 9.57129" stroke="white" />
            </svg>
          </div>
        </div>
        {modal && (
          <ModalBase closeModal={setModal}>
            <div className=" w-[700px] md:w-auto h-[400px] flex flex-col items-center justify-center">
              <h2 className="text-[40px] font-bold text-center sm:px-6 sm:text-2xl px-24 leading-[90%] text-white uppercase font-bicyclette">
                are you sure you
                <br /> want to log out?
              </h2>
              <div className="flex items-center mt-[30px] justify-center gap-[20px] ">
                <PrimaryOutlinedButton
                  onClick={() => {
                    window.localStorage.removeItem('userInfo');
                    window.localStorage.removeItem('login');
                    Auth.signOut().then(() => {
                      setUserSelectedMembership(null);
                      Init();
                      navigate('/login');
                    });
                  }}
                  className="rounded-md w-[108px] !px-0 uppercase"
                >
                  log out
                </PrimaryOutlinedButton>
                <PrimaryOutlinedButton
                  onClick={() => setModal(false)}
                  className="rounded-md w-[108px] !px-0 uppercase"
                >
                  cancel
                </PrimaryOutlinedButton>
              </div>
            </div>
          </ModalBase>
        )}
      </div>
    </>
  );
}

export default Sidebar;
