import React, { useEffect, useRef, useState } from 'react';

import { consentForm } from '@/api/mutation';
import CheckboxActive from '@/common/buttons/CheckboxActive';
import PrimaryOutlinedButton from '@/common/buttons/PrimaryOutlinedButton';
import ModalBase from '@/common/modals/ModalBase';
import { toastError } from '@/common/toasts/toast';
import MyReactQuill from '@/components/MyReactQuill';
import useFetch from '@/hooks/useFetch';
import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client';

interface IQueryString {
  token: string;
  memberId: string;
  memberContractKey: string;
  memberData: any;
}
function index() {
  const userPdf = useRef<any>();
  const [userInfo, setUserInfo] = useState<IQueryString>({
    token: '',
    memberId: '',
    memberContractKey: '',
    memberData: {},
  });

  const [isModalTC, setIsModalTc] = useState<boolean>(false);
  const [getGuardian] = useLazyQuery(gql`
    query getGuardianDetailByMemberId($memberId: ID!) {
      getGuardianDetailByMemberId(memberId: $memberId) {
        items {
          Id
          memberId
          name
        }
      }
    }
  `);
  const [guardian, setGuardian] = useState('');
  useEffect(() => {
    if (userInfo.memberId) {
      getGuardian({ variables: { memberId: userInfo?.memberId } }).then(
        (res) => {
          setGuardian(res.data.getGuardianDetailByMemberId.items[0].name);
        }
      );
      const questionCollection = questions;
      questionCollection[4].title = `I confirm the information provided on the Health questionnaire to be true & correct.`;
    }
  }, [userInfo.memberId]);
  // 44d4df84-73ca-4160-908c-fa3db21bb65a
  const [questions, setQuestions] = useState<
    Array<{ title: string; answer: boolean }>
  >([
    {
      title:
        'I confirm the information provided on this form to be true and correct <br/><span id="previewFile" class="font-bold text-xs cursor-pointer text-white">Membership Agreement.pdf</span>',
      answer: false,
    },
    {
      title: 'I agree to the Plus Fitness Terms and Conditions',
      answer: false,
    },
    {
      title:
        'I accept responsibility for the payment of my child’s membership \n (by direct debit or upfront payment) and any fees or fines that are \n incurred in connection with that membership, regardless of if I have \n been nominated as the payee of the account.',
      answer: false,
    },
    {
      title:
        'If I have been nominated as they payee of this membership,\n I confirm the billing information to be correct. \n a. I authorize recurring direct debits to be withdrawn from nominated details. \n b. I am aware I need to update ‘Plus Fitness’ of any changes to the \n nominated billing details.',
      answer: false,
    },
    {
      title:
        'I confirm the information provided on the Health questionnaire by \n “insert member name” to be true & correct.',
      answer: false,
    },
    {
      title:
        'I agree that my child’s membership may be cancelled due to a breach of the membership Terms and Conditions.',
      answer: false,
    },
    {
      title:
        'I give permission for my child to receive first aid, medical or ambulance assistance in the case of an accident or emergency and agree to pay any costs incurred.',
      answer: false,
    },
    {
      title:
        'I have been provided with a copy of the Privacy Policy and agree to be\n `bound by its terms.',
      answer: false,
    },
  ]);
  useEffect(() => {
    const parseQueryString = () => {
      const str = window.location.search;
      const objURL: any = {};

      str.replace(
        new RegExp('([^?=&]+)(=([^&]*))?', 'g'),
        function ($0, $1, $2, $3) {
          objURL[$1] = $3;
          return '';
        }
      );
      return objURL;
    };
    const queryString: IQueryString = parseQueryString();
    setUserInfo(queryString);
    fetch(`${process.env.BUCKET_LINK}${queryString.memberContractKey}`, {
      method: 'GET',
      headers: {},
    })
      .then((res) => res.blob())
      .then((blob) => {
        userPdf.current = blob;
        document
          ?.getElementById('previewFile')
          ?.addEventListener('click', previewFile);
      });
    return document
      ?.getElementById('previewFile')
      ?.removeEventListener('click', previewFile);
  }, []);
  const [fileReview, setFileReview] = useState(false);
  const previewFile = () => {
    setFileReview(true);
    const blob = new Blob([userPdf.current], {
      type: 'application/pdf',
    });
    const blobURL = URL.createObjectURL(blob);
    window.open(blobURL);
  };
  const [consentApi] = useMutation(consentForm);
  const [loading, setLoading] = useState(false);
  const [sucess, setSuccess] = useState(false);

  const { data: dataTermAndCondition } = useFetch({
    params: 'term-and-condition',
    populate: '',
  });

  const termAndCondition = dataTermAndCondition?.data?.attributes;

  const submit = async () => {
    try {
      setLoading(true);
      await consentApi({
        variables: {
          arequestionsAnswered: true,
          memberId: userInfo.memberId,
          signature: guardian,
        },
      });
      // navigate('/login');
      setSuccess(true);
    } catch (e: any) {
      toastError(e.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="w-[100vw] bg-black">
      {isModalTC && (
        <ModalBase
          noBaseHeader
          onClickOutSide={(): void => setIsModalTc(false)}
        >
          <div
            onClick={() => setIsModalTc(false)}
            className="flex items-center absolute right-6 top-6 sm:top-3 sm:right-2 justify-center w-8 h-8 rounded-full border border-white cursor-pointer z-50"
          >
            <div className="w-4 h-4 block">
              <img
                src="/icons/close.png"
                alt="close"
                className="object-fit w-full"
              />
            </div>
          </div>
          <div className="h-[96vh] overflow-y-auto" id="scroll-hidden">
            <section className="bg-dark pt-6 pb-20">
              <div className="containerPage">
                <h1 className="heading heading-underline text-white">
                  {termAndCondition?.title}
                </h1>
                <div className="contentWrapper">
                  <MyReactQuill content={termAndCondition?.content} />
                </div>
              </div>
            </section>
          </div>
        </ModalBase>
      )}
      <div className="max-w-[793px] mx-auto bg-black min-h-[100vh] p-10">
        <img
          src="/icons/PF-logo_inline.png"
          className="object-contain max-h-[50px] max-w-[270px]"
        />
        <div className="text-[50px] mt-7 sm:mb-7 mb-12 text-white font-black tracking-tighter leading-[90%]">
          CONSENT FORM
          <br />
          JUNIOR MEMBERS
        </div>
        <p className="text-white text-lg font-medium ">
          I have read and understand the following statements:
        </p>
        {questions.map((question, index: number) => (
          <div
            key={index}
            style={{
              borderBottom:
                index < questions.length - 1 ? '1px solid white ' : '',
            }}
            className="gap-[40px] sm:gap-6 py-3 flex items-start text-white"
          >
            <p className="w-[10px] leading-[130%]">{index + 1}.</p>
            {index !== 1 && (
              <p
                onClick={(): void => {
                  index === 1 && setIsModalTc(true);
                }}
                className="leading-[130%]"
                dangerouslySetInnerHTML={{
                  __html: question.title.replaceAll('\n', '<br/>'),
                }}
              ></p>
            )}

            {index === 1 && (
              <p
                onClick={(): void => {
                  index === 1 && setIsModalTc(true);
                }}
                className="leading-[130%] cursor-pointer"
              >
                I agree to the Plus Fitness{' '}
                <span className="underline">Terms and Conditions</span>
              </p>
            )}

            <div className="ml-auto">
              <CheckboxActive
                onChecked={(value: boolean) => {
                  const data = [...questions];
                  data[index] = { ...question, answer: value };
                  setQuestions(data);
                }}
              />
            </div>
          </div>
        ))}
        <div className="flex gap-4 sm:flex-col mt-6">
          <div className="w-4/5 sm:w-full relative">
            <p className="font-bold absolute text-base leading-[90%] left-3 top-3">
              PARENT/GUARDIAN:
            </p>

            <textarea
              readOnly
              value={guardian}
              onChange={(e) => {
                // setSignature(e.target.value);
              }}
              className="w-full h-full pl-[165px] pt-[11px]"
            />
          </div>
          <div className="flex w-1/5 sm:w-3/5 flex-col justify-between gap-5">
            <div className="relative">
              {loading && (
                <div className="absolute w-full h-full flex items-center justify-center bg-gray ">
                  <div className="ldsEllipsis ">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </div>
              )}
              <PrimaryOutlinedButton
                // disabled={signature !== ''}
                onClick={() => {
                  if (!fileReview) {
                    toastError(
                      'Please review your member confirmation file in question number 1 by click on the "Membership Agreement.pdf"'
                    );
                  } else
                    questions.filter((i) => !i.answer).length == 0
                      ? submit()
                      : toastError(
                          'Please accept all of the questions and also review your signature'
                        );
                }}
                className={`w-full  ${'!text-primary hover:!bg-primary hover:!text-white font-bold !bg-white'}`}
              >
                Approve <br /> & Submit
              </PrimaryOutlinedButton>
            </div>
          </div>
        </div>
      </div>

      {sucess && (
        <div
          className={`fixed top-0 left-0 flex items-center transition-opacity ease-in-out duration-500 h-[100vh] w-[100vw] z-50 bg-[rgba(14,13,13,0.6)]`}
        >
          <div className="m-auto h-[400px] w-[35%] sm:w-[350px] lg:w-[400px] bg-richBlack flex justify-center items-center px-[35px]">
            <div className="mb-8">
              {
                <div className="success-animation">
                  <svg
                    className="checkmark"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 52 52"
                  >
                    <circle
                      className="checkmark__circle"
                      cx="26"
                      cy="26"
                      r="25"
                      fill="none"
                    />
                    <path
                      className="checkmark__check"
                      fill="none"
                      d="M14.1 27.2l7.1 7.2 16.7-16.8"
                    />
                  </svg>
                </div>
              }
              <div className="mt-5 text-center text-2xl text-primary  ">
                Submit success
              </div>
              <div className="mt-5 text-center  text-white tracking-[1.4px] leading-[25px]">
                Thank you for submitting your consent form. Our member assist
                team is reviewing it now. We will let you know once the review
                is complete. Thank you.
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default index;
