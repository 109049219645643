import React, { useContext, useEffect, useState } from 'react';

import axios from 'axios';
import moment from 'moment';

import { listMembershipId } from '@/api/mutation';
import ArrowButton from '@/common/buttons/ArrowButton';
import PayWithVivaPay from '@/common/payment/paymentWithVivaPay';
import { toastDismiss, toastError } from '@/common/toasts/toast';
import { useLazyQuery } from '@apollo/client';
import CheckboxActive from '@/common/buttons/CheckboxActive';
import { RegisterContext } from '@/context/RegisterContext';
import { googleMapDirectionPoint } from '@/helpers/googleMap.helper';
import { calculateDateFromSpecificDate } from '@/utils/CalculateDateFromSpecificDate';
import plusIcon from '../../assets/images/plusIcon.svg';
import Flatpickr from 'react-flatpickr';
import dateIcon from '../../assets/images/dateIcon.svg';
import PrimaryButton from '@/common/buttons/PrimaryButton';
import PrimaryOutlinedButton from '@/common/buttons/PrimaryOutlinedButton';

interface SlideProps {
  onNext: () => void;
  onPrev: () => void;
}
interface PaymentProps {
  tokenId: string;
  prospectId: string;
  memberId: string;
  setContact: () => void;
  contact: any;
  isApiOver18Fail: boolean;
  setIsPaymentSuccess: (v) => void;
  membershipType: string;
  voucherDetail: any;
}
const Payment: React.FC<SlideProps> = ({
  onNext,
  onPrev,
  info,
  setInfo,
  contact,
  handleCallApiUnder18,
  handleCallApiOver18,
  setIsPaymentSuccess,
  useBank,
  membershipType,
  voucherDetail,
  setCloseModalAfterAutoSignUp,
  setAutoSignUp,
  autoSignUp,
  slideMove,
}) => {
  const [isSameInfo, setIsSameInfo] = useState<boolean>(false);
  const [isPayment, setIsPayment] = useState<boolean>(false);
  const [paymentData, setPaymentData] = useState({
    tokenId: '',
    prospectId: '',
    memberId: '',
    membershipStartDate: '',
  });
  const yourClub = useContext(RegisterContext).yourClub;

  const [percentPrice, setPercentPrice] = useState<number>(0);
  const [age, setAge] = useState(18);
  const [currentMaintenance, setCurrentMaintenance] = useState({});

  const complimentaryNoCost =
    Object.keys(voucherDetail || {}).length &&
    (voucherDetail?.type === 'COMPLIMENTARY' ||
      voucherDetail?.type === 'VISIT_PASS') &&
    !(
      voucherDetail?.cost +
      voucherDetail?.joiningFee +
      voucherDetail?.activationFee +
      voucherDetail?.passFee
    );
  const handleNextUnder18 = async () => {
    try {
      const handleCallApiInput = {
        prospectId: paymentData?.prospectId,
        membershipStartDate: moment(info?.membershipStartDate).format(
          'YYYY-MM-DD'
        ),
      };
      if (complimentaryNoCost) {
        setIsPayment(true);
        setIsPaymentSuccess(true);
        setInfo(handleCallApiInput);
        await handleCallApiUnder18(handleCallApiInput);
      } else {
        makeApiRequest().then(async (res) => {
          if (res?.data?.success) {
            setIsPayment(true);
            setIsPaymentSuccess(true);
            setInfo(handleCallApiInput);
            await handleCallApiUnder18(handleCallApiInput);
          } else {
            toastDismiss();
            setIsPayment(false);
            setIsPaymentSuccess(false);
            toastError('Please fill all of the payment info');
          }
        });
      }
    } catch (e) {
      // toastError(e.message);
    } finally {
    }
  };
  const handleNextOver18 = async () => {
    try {
      const handleCallApiInput = {
        prospectId: paymentData?.prospectId,
        membershipStartDate: moment(info?.membershipStartDate).format(
          'YYYY-MM-DD'
        ),
      };
      if (complimentaryNoCost) {
        setIsPayment(true);
        setIsPaymentSuccess(true);

        await handleCallApiOver18(handleCallApiInput);
      } else {
        makeApiRequest().then(async (res) => {
          if (res?.data?.success) {
            setIsPayment(true);
            setIsPaymentSuccess(true);

            await handleCallApiOver18(handleCallApiInput);
          } else {
            toastDismiss();
            setIsPayment(false);
            setIsPaymentSuccess(false);
            toastError('Please fill all of the payment info');
          }
        });
      }
    } catch (e) {
      // toastError(e.message);
    } finally {
    }
  };
  const [membershipData, setMembershipData] = useState([]);
  const [listMemberShipApi] = useLazyQuery(listMembershipId);

  const apiEndpoint = `${process.env.VIVAPAY_ENPOINT}/${paymentData?.tokenId}`;
  const makeApiRequest = async () => {
    try {
      const response = await axios.get(apiEndpoint);
      return response; // Return the response when received
    } catch (error) {
      return null; // Return null on error
    }
  };
  useEffect(() => {
    listMemberShipApi().then((membership) => {
      membership = membership.data;
      if (membership) {
        setMembershipData(membership?.getMembershipByBrandId.items);
      }
    });
    makeApiRequest().then((res) => {
      if (res?.data?.success) {
        setIsPayment(true);
      } else {
        setIsPayment(false);
      }
    });
  }, []);
  const paymentSuccess = () => {
    setIsPayment(true);
  };
  const paymentError = () => {
    setIsPayment(false);
  };
  const [membershipCurrent, setMembershipCurrent] = useState();
  useEffect(() => {
    if (info?.membershipId) {
      const currentMembership = membershipData
        ?.filter((i) => i.id === info.membershipId)
        .map((i) => {
          const membershipLocation = i.membershipLocations?.items.filter(
            (item) => item.locationId === info?.memberDetails?.homeLocationId
          );
          const costPriceFromLocation = membershipLocation[0]?.costPrice ?? -1;
          const currentItem = {
            ...i,
            costPrice: costPriceFromLocation,
            joiningFee: membershipLocation[0]?.joiningFee ?? i.joiningFee,
            joiningFee2: membershipLocation[0]?.activationFee ?? i.joiningFee2,
            joiningFee3: membershipLocation[0]?.fobFee ?? i.joiningFee3,
          };
          // const costPriceFromLocation =
          //   i.membershipLocations?.items.filter(
          //     (item) => item.locationId === info?.memberDetails?.homeLocationId
          //   )[0]?.costPrice ?? -1;
          return { ...currentItem };
        })[0];
      setMembershipCurrent(currentMembership);
    }
    const ageInfo = moment(new Date()).diff(
      moment(info?.memberDetails?.dob),
      'years'
    );
    info?.memberDetails?.dob && info?.memberDetails?.dob != ''
      ? setAge(ageInfo)
      : setAge(18);
  }, [info]);

  useEffect(() => {
    if (membershipCurrent?.paymentFrequency.type.toLowerCase() !== 'weeks') {
      setIsSameInfo(true);
    } else setIsSameInfo(false);
  }, [membershipCurrent]);

  useEffect(() => {
    const membershipLocation =
      voucherDetail?.membershipDetail?.membershipLocations.items.filter(
        (item) => item.locationId === info?.memberDetails?.homeLocationId
      );
    setPercentPrice(membershipLocation?.[0]?.costPrice);
  }, [voucherDetail]);

  useEffect(() => {
    const getLocal = localStorage.getItem('payment');
    if (getLocal) {
      setPaymentData(JSON.parse(getLocal));
    }
  }, [onNext]);

  React.useEffect(() => {
    setCurrentMaintenance(
      yourClub?.locationMaintenance?.items?.filter(
        (item) =>
          moment().isBetween(
            item.startDateTime,
            item.endDateTime,
            null,
            '[]'
          ) && !item.isDeleted
      )?.[0]
    );
  }, [yourClub]);

  const isPaymentDelay: boolean =
    membershipCurrent?.paymentDelay?.amount !== 0 &&
    membershipCurrent?.paymentDelay !== null;

  const isVoucherDelay: boolean = Object.keys(voucherDetail || {}).length > 0;
  voucherDetail?.paymentDelay?.amount !== 0 &&
    voucherDetail?.paymentDelay !== null;

  let nextDebit;

  if (isPaymentDelay || isVoucherDelay) {
    nextDebit = calculateDateFromSpecificDate({
      type: isVoucherDelay
        ? voucherDetail?.paymentDelay?.type
        : membershipCurrent?.paymentDelay?.type,
      amount: isVoucherDelay
        ? voucherDetail?.paymentDelay?.amount
        : membershipCurrent?.paymentDelay?.amount,
      date:
        yourClub?.openDate && moment(yourClub?.openDate).isAfter(moment())
          ? yourClub.openDate
          : moment(),
    });
  }

  const [vivaPayApprove, setVivaPayApprove] = useState(false);
  return (
    <>
      <div className="flex gap-2 md:self-start self-end h-[29px] items-start  border-b border-b-primary my-[30px]">
        <h2 className="heading2 text-primary text-start uppercase font-bicyclette">
          Payment Details
        </h2>
      </div>
      <div
        id="scroll-hidden"
        className="h-full md:mx-0 overflow-y-scroll pb-[70px] sm:pb-[150px]"
      >
        <div className="mt-[-50px] block">
          <div className="text-white w-full py-[23px] px-5 bg-richBlack rounded-md">
            <h2 className="text-[25px] uppercase font-bicyclette leading-[90%] text-white font-bold">
              Your membership
            </h2>

            <div className="border-y border-primary py-[14px] mt-5">
              <div className="flex items-center gap-[5px]">
                <img className="w-[14px] h-[14px]" src={plusIcon} alt="icon" />
                <h2 className="text-[20px] uppercase font-bicyclette leading-[90%] text-white font-bold">
                  {yourClub?.name}
                  {yourClub?.address?.state
                    ? ', ' + yourClub?.address?.state
                    : ''}
                </h2>

                <p
                  className="text-xs leading-[160%] text-[rgba(255,255,255,0.57)] underline ml-auto cursor-pointer"
                  onClick={() => slideMove(0)}
                >
                  Edit
                </p>
              </div>
              <h5
                className="text-xs leading-[160%] text-textSecondary font-normal mt-[3px] cursor-pointer "
                onClick={() => {
                  const link = document.createElement('a');
                  link.href = googleMapDirectionPoint([
                    yourClub.geoLocation.longitude,
                    yourClub.geoLocation.latitude,
                  ]);
                  link.target = '_blank';
                  link.click();
                }}
              >
                {yourClub?.address1}
              </h5>
            </div>

            <div className="border-b border-textSecondary py-[14px]">
              <div className="flex flex-col gap-3">
                <div className="flex items-center justify-between">
                  <p className="text-sm leading-[160%] text-textSecondary font-normal">
                    Membership Plan
                  </p>
                  <p
                    className="text-xs leading-[160%] text-[rgba(255,255,255,0.57)] underline ml-auto cursor-pointer"
                    onClick={() => slideMove(1)}
                  >
                    Edit
                  </p>
                </div>
                {voucherDetail.type !== 'COMPLIMENTARY' && (
                  <div className="flex items-center justify-between">
                    <p className="text-[11px] uppercase leading-[140%] text-white font-medium">
                      {membershipCurrent?.membershipName}
                    </p>
                    {Object.keys(voucherDetail || {}).length ? (
                      <p className="text-[11px] uppercase leading-[140%] text-white font-medium">
                        {voucherDetail.type === 'PERCENT'
                          ? `$${(
                              percentPrice -
                              percentPrice * (voucherDetail?.cost * 0.01)
                            ).toFixed(2)}`
                          : `$${voucherDetail?.cost.toFixed(2) ?? '0.00'}`}
                      </p>
                    ) : (
                      <p className="text-[11px] uppercase leading-[140%] text-white font-medium">
                        $
                        {Object.keys(voucherDetail || {}).length
                          ? voucherDetail.cost.toFixed(2)
                          : membershipCurrent?.costPrice?.toFixed(2) ?? '0.00'}
                      </p>
                    )}
                  </div>
                )}

                {Object.keys(voucherDetail || {}).length ? (
                  <div className="flex flex-col gap-3">
                    <div className="flex items-center justify-between">
                      <p className="text-[11px] uppercase leading-[140%] text-white font-medium">
                        JOINING FEE
                      </p>
                      <p className="text-[11px] uppercase leading-[140%] text-white font-medium">
                        ${voucherDetail?.joiningFee.toFixed(2) ?? '0.00'}
                      </p>
                    </div>
                    <div className="flex items-center justify-between">
                      <p className="text-[11px] uppercase leading-[140%] text-white font-medium">
                        ACTIVATION FEE
                      </p>
                      <p className="text-[11px] uppercase leading-[140%] text-white font-medium">
                        ${voucherDetail?.activationFee.toFixed(2) ?? '0.00'}
                      </p>
                    </div>
                    <div className="flex items-center justify-between">
                      <p className="text-[11px] uppercase leading-[140%] text-white font-medium">
                        PASS FEE
                      </p>
                      <p className="text-[11px] uppercase leading-[140%] text-white font-medium">
                        ${voucherDetail?.passFee.toFixed(2) ?? '0.00'}
                      </p>
                    </div>
                  </div>
                ) : (
                  <div className="flex flex-col gap-3">
                    <div className="flex items-center justify-between">
                      <p className="text-[11px] uppercase leading-[140%] text-white font-medium">
                        JOINING FEE
                      </p>
                      <p className="text-[11px] uppercase leading-[140%] text-white font-medium">
                        ${membershipCurrent?.joiningFee?.toFixed(2) ?? '0.00'}
                      </p>
                    </div>
                    <div className="flex items-center justify-between">
                      <p className="text-[11px] uppercase leading-[140%] text-white font-medium">
                        ACTIVATION FEE
                      </p>
                      <p className="text-[11px] uppercase leading-[140%] text-white font-medium">
                        ${membershipCurrent?.joiningFee2?.toFixed(2) ?? '0.00'}
                      </p>
                    </div>
                    <div className="flex items-center justify-between">
                      <p className="text-[11px] uppercase leading-[140%] text-white font-medium">
                        ACCESS FEE
                      </p>
                      <p className="text-[11px] uppercase leading-[140%] text-white font-medium">
                        ${membershipCurrent?.joiningFee3?.toFixed(2) ?? '0.00'}
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="border-b border-textSecondary py-[14px]">
              <div className="flex items-center justify-between">
                <p className="text-xs leading-[160%] text-textSecondary font-normal ">
                  Promo code
                </p>
                <input
                  className="bg-transparent border border-solid border-white border-opacity-40 text-primary text-[11px] font-medium leading-[140%] tracking-[1.1px] uppercase rounded-md px-[8px] py-[10px] w-[147px] h-[33px]"
                  defaultValue={
                    Object.keys(voucherDetail || {}).length
                      ? voucherDetail?.voucherCode
                      : 'PF-'
                  }
                  disabled={true}
                />
              </div>
            </div>

            {age >= 18 && (
              <div className="border-b border-textSecondary py-[14px]">
                <div className="flex items-center justify-between">
                  <p className="text-xs leading-[160%] text-textSecondary font-normal">
                    {/* Choose your start date */}
                    Your start date
                  </p>
                  {age >= 18 && (
                    <label>
                      <div className="flatpickrContainer relative bg-transparent border border-solid border-white border-opacity-40 text-primary text-[11px] font-medium leading-[140%] tracking-[1.1px] uppercase rounded-md px-[8px] py-[10px] w-[147px] h-[33px]">
                        <Flatpickr
                          disabled={
                            isPaymentDelay ||
                            isVoucherDelay ||
                            moment(new Date()).diff(
                              moment(contact?.dob),
                              'years'
                            ) < 18
                          }
                          value={
                            info.membershipStartDate
                              ? new Date(info.membershipStartDate)
                              : new Date()
                          }
                          onChange={(dateObj, dateStr) =>
                            setInfo({
                              ...info,
                              membershipStartDate: new Date(dateObj[0])
                                .fp_incr(1)
                                .toISOString()
                                .split('T')[0],
                            })
                          }
                          options={{
                            disableMobile: 'true',
                            dateFormat: 'd/m/Y',
                            enableTime: false,
                            time_24hr: false,
                            allowInput: false,
                            minDate:
                              yourClub?.openDate &&
                              moment(yourClub.openDate).isAfter(moment())
                                ? new Date(yourClub.openDate)
                                : Object.keys(currentMaintenance || {})
                                    ?.length > 0
                                ? new Date(
                                    moment(currentMaintenance?.endDateTime)
                                      .add(1, 'days')
                                      .startOf('day')
                                      .toDate()
                                  )
                                : 'today',
                            maxDate:
                              yourClub?.openDate &&
                              moment(yourClub?.openDate).isAfter(moment())
                                ? new Date(
                                    new Date(
                                      yourClub?.openDate
                                        .split('/')
                                        .reverse()
                                        .join('-')
                                    ).setMonth(
                                      new Date(
                                        yourClub.openDate
                                          .split('/')
                                          .reverse()
                                          .join('-')
                                      ).getMonth() + 3
                                    )
                                  )
                                : new Date(
                                    new Date().setMonth(
                                      new Date().getMonth() + 3
                                    )
                                  ),
                          }}
                        />
                        <div className="absolute right-3 bottom-2">
                          <img className="w-auto h-auto" src={dateIcon} />
                        </div>
                      </div>
                    </label>
                  )}
                </div>
              </div>
            )}
            {membershipType === 'weekly' &&
              age >= 18 &&
              voucherDetail?.type !== 'COMPLIMENTARY' && (
                <div className="border-b border-textSecondary py-[14px]">
                  <div className="flex text-sm items-center justify-between">
                    <p className="text-xs leading-[160%] text-textSecondary font-normal">
                      Next direct debit
                    </p>

                    {isVoucherDelay || isPaymentDelay
                      ? moment(nextDebit).format('DD/MM/YYYY')
                      : age >= 18 &&
                        (info.membershipStartDate
                          ? moment(info.membershipStartDate)
                              .add(
                                membershipData.filter(
                                  (i) => i.id === info.membershipId
                                )[0]?.paymentFrequency.amount * 7,
                                'days'
                              )
                              .format('DD/MM/yyyy')
                          : moment()
                              .add(
                                membershipData.filter(
                                  (i) => i.id === info.membershipId
                                )[0]?.paymentFrequency.amount * 7,
                                'days'
                              )
                              .format('DD/MM/yyyy'))}
                  </div>
                </div>
              )}

            {membershipCurrent?.paymentFrequency.type.toLowerCase() ==
              'weeks' && (
              <div>
                {voucherDetail.type !== 'COMPLIMENTARY' && (
                  <div className="border-b border-textSecondary py-[14px]">
                    <div className="flex items-center justify-between">
                      <p className="text-xs leading-[160%] text-textSecondary font-normal">
                        Cost per fortnight
                      </p>
                      {Object.keys(voucherDetail || {}).length ? (
                        <p className="text-sm uppercase tracking-[1.4px] leading-[140%] text-white font-medium">
                          {voucherDetail.type === 'PERCENT'
                            ? `$${(
                                percentPrice -
                                percentPrice * (voucherDetail?.cost * 0.01)
                              ).toFixed(2)}`
                            : `$${voucherDetail?.cost.toFixed(2)}`}
                        </p>
                      ) : (
                        <p className="text-sm uppercase tracking-[1.4px] leading-[140%] text-white font-medium">
                          ${membershipCurrent?.costPrice.toFixed(2)}
                        </p>
                      )}
                    </div>
                  </div>
                )}
              </div>
            )}

            <div className="pt-[26px]">
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-x-2">
                  <p className="text-sm leading-[140%] text-white font-medium tracking-[1.4px] uppercase">
                    total due today*
                  </p>
                  {/* <img src={questionIcon} className="w-7 h-7 cursor-pointer" /> */}
                </div>
                <p className="text-lg uppercase leading-[90%] text-primary font-bold font-bicyclette">
                  <span className="text-lg uppercase leading-[90%] text-primary font-bold font-gilroy">
                    $
                  </span>

                  {Object.keys(voucherDetail || {}).length
                    ? isVoucherDelay
                      ? (
                          voucherDetail.joiningFee +
                          voucherDetail.activationFee +
                          voucherDetail.passFee
                        ).toFixed(2)
                      : voucherDetail.type === 'PERCENT'
                      ? (
                          percentPrice -
                          percentPrice * (voucherDetail?.cost * 0.01) +
                          voucherDetail.joiningFee +
                          voucherDetail.activationFee +
                          voucherDetail.passFee
                        ).toFixed(2)
                      : (
                          voucherDetail.cost +
                          voucherDetail.joiningFee +
                          voucherDetail.activationFee +
                          voucherDetail.passFee
                        ).toFixed(2)
                    : (
                        (isPaymentDelay || isVoucherDelay
                          ? 0
                          : membershipCurrent?.costPrice) +
                        membershipCurrent?.joiningFee +
                        membershipCurrent?.joiningFee2 +
                        membershipCurrent?.joiningFee3
                      )?.toFixed(2)}
                </p>
              </div>
            </div>
            {!useBank && (
              <div className="text-white text-start mt-[20px] flex gap-2">
                <CheckboxActive
                  onChecked={(v) => setVivaPayApprove(v)}
                  isChecked={vivaPayApprove}
                />

                <p className="text-white font-thin ">
                  The Agreement outlines the applicable Terms and Conditions to
                  your payment request.
                  <br />
                  You agree to these terms and conditions.
                </p>
              </div>
            )}
            <div className="flex items-start mt-5 ">
              <PayWithVivaPay
                autoSignUp={autoSignUp}
                setAutoSignUp={setAutoSignUp}
                setCloseModalAfterAutoSignUp={setCloseModalAfterAutoSignUp}
                apiEndpoint={apiEndpoint}
                info={info}
                callApiPayment={makeApiRequest}
                isPayment={isPayment}
                handleNextOver18={handleNextOver18}
                handleNextUnder18={handleNextUnder18}
                paymentSuccess={paymentSuccess}
                paymentError={paymentError}
                vivaPayApprove={vivaPayApprove}
                cardRequestId={paymentData?.tokenId}
                cost={
                  Object.keys(voucherDetail || {}).length
                    ? isVoucherDelay
                      ? voucherDetail.joiningFee +
                        voucherDetail.activationFee +
                        voucherDetail.passFee
                      : voucherDetail.type === 'PERCENT'
                      ? (
                          percentPrice -
                          percentPrice * (voucherDetail?.cost * 0.01) +
                          voucherDetail.joiningFee +
                          voucherDetail.activationFee +
                          voucherDetail.passFee
                        )?.toFixed(2)
                      : (
                          voucherDetail.cost +
                          voucherDetail.joiningFee +
                          voucherDetail.activationFee +
                          voucherDetail.passFee
                        )?.toFixed(2)
                    : Number(
                        (isPaymentDelay ? 0 : membershipCurrent?.costPrice) +
                          membershipCurrent?.joiningFee +
                          membershipCurrent?.joiningFee2 +
                          membershipCurrent?.joiningFee3
                      )?.toFixed(2)
                }
              />
              <div className="mt-[10px]">
                <div className="flex pl-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="9"
                    height="10"
                    viewBox="0 0 9 10"
                    fill="none"
                  >
                    <path
                      d="M7.94243 0.832591L4.37197 0.017006C4.27343 -0.00566867 4.17103 -0.00566867 4.07248 0.017006L0.502025 0.832591C0.374278 0.827714 0.24979 0.873572 0.155735 0.960156C0.0616788 1.04674 0.0056898 1.16702 7.15405e-08 1.29473V3.46625C-0.00011109 4.32733 0.129322 5.18351 0.383948 6.00608C0.748344 7.16573 1.39034 8.26518 2.38656 9.04245C2.92103 9.45803 3.53219 9.76413 4.18508 9.94327C4.22292 9.95186 4.26219 9.95186 4.30003 9.94327C7.3755 9.07842 8.44444 5.95525 8.44444 3.46625V1.29473C8.43876 1.16702 8.38278 1.04674 8.28872 0.960156C8.19466 0.873572 8.07018 0.827714 7.94243 0.832591ZM7.95806 3.58746C7.95806 5.78791 7.00094 8.6327 4.25311 9.38104C4.24282 9.38339 4.23213 9.38339 4.22183 9.38104C1.474 8.6327 0.48638 5.784 0.48638 3.58746V1.60987C0.490646 1.51444 0.532533 1.42459 0.602873 1.35997C0.673213 1.29534 0.766284 1.2612 0.861728 1.26502L4.22418 0.624595L7.58663 1.26502C7.68207 1.2612 7.77515 1.29534 7.84548 1.35997C7.91582 1.42459 7.95771 1.51444 7.96198 1.60987L7.95806 3.58746Z"
                      fill="#C4C4C4"
                    />
                    <path
                      d="M5.63105 4.23975H2.81284C2.60555 4.23975 2.4375 4.40779 2.4375 4.61509V6.24392C2.4375 6.45122 2.60555 6.61927 2.81284 6.61927H5.63105C5.83834 6.61927 6.00639 6.45122 6.00639 6.24392V4.61509C6.00639 4.40779 5.83834 4.23975 5.63105 4.23975Z"
                      fill="#C4C4C4"
                    />
                    <path
                      d="M5.37672 4.23952H5.05689V4.08313C5.05026 3.86637 4.95948 3.6607 4.80381 3.50972C4.64813 3.35874 4.43979 3.27431 4.22293 3.27431C4.00606 3.27431 3.79772 3.35874 3.64204 3.50972C3.48637 3.6607 3.3956 3.86637 3.38897 4.08313V4.23952H3.07227V4.08313C3.0801 3.78232 3.20509 3.49647 3.42062 3.28648C3.63614 3.07649 3.92515 2.95898 4.22606 2.95898C4.52696 2.95898 4.81597 3.07649 5.03149 3.28648C5.24702 3.49647 5.37201 3.78232 5.37985 4.08313L5.37672 4.23952Z"
                      fill="#C4C4C4"
                    />
                  </svg>
                  <span className="text-gray text-[10px] font-thin pl-1 item-center">
                    {' '}
                    100% secure payment
                  </span>
                </div>
                <div className="flex items-center h-[33px] pl-4">
                  <span className="text-gray text-[12px] font-bold ">
                    Powered by
                  </span>
                  <div className="w-[100px] h-[35px]  flex items-center mt-[5px]">
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="85"
                        height="36"
                        viewBox="0 0 85 36"
                        fill="none"
                      >
                        <path
                          d="M78.5231 2.44297C79.7785 2.44398 80.9821 2.93601 81.8698 3.81104C82.7574 4.68607 83.2565 5.87258 83.2576 7.11006V21.8381C83.256 23.0753 82.7567 24.2613 81.8691 25.1359C80.9815 26.0105 79.7781 26.5023 78.5231 26.5033H50.9348C49.6802 26.5018 48.4773 26.0098 47.5901 25.1352C46.7029 24.2607 46.2038 23.0749 46.2023 21.8381V7.11006C46.2033 5.87291 46.7022 4.68669 47.5894 3.81171C48.4767 2.93674 49.6798 2.44448 50.9348 2.44297H78.5231ZM78.5231 1.72949H50.9348C49.489 1.73401 48.1037 2.30246 47.0817 3.31065C46.0597 4.31883 45.484 5.68478 45.4805 7.11006V21.8381C45.4845 23.2634 46.0607 24.6292 47.0831 25.637C48.1054 26.6448 49.4909 27.2128 50.9368 27.2168H78.5231C79.9691 27.2128 81.3548 26.6449 82.3775 25.6371C83.4002 24.6293 83.9768 23.2636 83.9813 21.8381V7.11006C83.9773 5.68428 83.4009 4.31804 82.3782 3.30986C81.3554 2.30168 79.9695 1.73351 78.5231 1.72949Z"
                          fill="#C4C4C4"
                        />
                        <path
                          d="M54.9239 13.9158C54.4071 13.904 53.8975 14.0358 53.4532 14.2963C53.0159 14.5626 52.6614 14.9424 52.4283 15.3941C52.1729 15.8852 52.0443 16.4309 52.0539 16.9828C52.0382 17.2602 52.0817 17.5377 52.1814 17.7976C52.2811 18.0574 52.4349 18.2938 52.6329 18.4915C53.0189 18.8568 53.5555 19.0376 54.2503 19.0376C54.7695 19.0467 55.2807 18.9108 55.7249 18.6456C56.1587 18.3811 56.5111 18.0048 56.744 17.5574C56.9987 17.0667 57.1267 16.5217 57.1165 15.9706C57.1165 15.325 56.9235 14.8214 56.5374 14.4599C56.1572 14.0965 55.6168 13.9158 54.9239 13.9158Z"
                          fill="#C4C4C4"
                        />
                        <path
                          d="M66.3738 15.9706C66.3738 15.325 66.1808 14.8214 65.7948 14.4599C65.4087 14.0984 64.8696 13.917 64.1774 13.9158C63.6606 13.904 63.1509 14.0358 62.7067 14.2963C62.2736 14.5643 61.9232 14.9439 61.6934 15.3941C61.4386 15.8854 61.3107 16.4311 61.3209 16.9828C61.3209 17.6259 61.5139 18.1243 61.8999 18.4915C62.2859 18.8587 62.8225 19.0376 63.5173 19.0376C64.0306 19.0435 64.5353 18.9077 64.9745 18.6456C65.4083 18.3811 65.7607 18.0048 65.9936 17.5574C66.251 17.0674 66.3816 16.5223 66.3738 15.9706Z"
                          fill="#C4C4C4"
                        />
                        <path
                          d="M75.9329 8.5997L50.7978 2.92994C47.8718 2.26973 45.4785 4.14951 45.4785 7.11567V21.8437C45.4785 24.8023 47.8718 27.7627 50.7978 28.4229L75.9329 34.0927C78.8589 34.7529 81.2522 32.8712 81.2522 29.907V15.1789C81.2522 12.2204 78.8589 9.25991 75.9329 8.5997ZM58.464 18.3391C58.0692 19.0564 57.4828 19.6535 56.7683 20.0657C56.0538 20.4778 55.2386 20.6894 54.4108 20.6774C53.822 20.6905 53.2375 20.5755 52.6989 20.3407C52.2296 20.1326 51.8343 19.7906 51.564 19.3589L50.7225 23.5447H48.777L51.0332 12.3783H52.8861L52.7143 13.2383C53.3444 12.7284 54.1101 12.4082 54.9198 12.3157C55.7296 12.2233 56.5492 12.3626 57.2808 12.7169C57.8347 12.9971 58.2945 13.4293 58.6049 13.9612C58.9381 14.5453 59.1048 15.2073 59.0874 15.8772C59.0973 16.737 58.8826 17.5848 58.464 18.3391ZM63.0131 20.6774C62.3674 20.6866 61.7292 20.5396 61.1545 20.2494C60.6031 19.9657 60.1466 19.531 59.8401 18.9974C59.5075 18.4104 59.3408 17.7461 59.3576 17.0739C59.3446 16.2147 59.5567 15.3667 59.9733 14.6119C60.3626 13.8998 60.9436 13.3073 61.6524 12.8996C62.3775 12.4862 63.2024 12.2739 64.0399 12.285C65.3678 12.285 66.3116 12.7195 66.8713 13.5883L67.0971 12.3802H69.0426L67.3924 20.5709H65.5531L65.7249 19.7261C64.9754 20.3697 64.0065 20.7096 63.0131 20.6774ZM72.8873 21.292C72.3186 22.1799 71.7769 22.7957 71.2622 23.1394C70.7049 23.4942 70.0512 23.6734 69.3881 23.6531C68.9706 23.6557 68.5553 23.5914 68.1587 23.4629C67.8069 23.3604 67.4826 23.1823 67.2091 22.9415L68.1741 21.5469C68.5447 21.8885 69.0352 22.0754 69.5425 22.0682C69.8528 22.0741 70.1573 21.9857 70.4149 21.8152C70.7278 21.579 70.9901 21.2842 71.1869 20.9476L71.4513 20.55L69.4846 12.3802H71.4301L72.8294 18.4552L76.6722 12.3821H78.6177L72.8873 21.292Z"
                          fill="#C4C4C4"
                        />
                        <path
                          d="M14.9593 8.50073L9.58217 20.6793H7.31628L1.91211 8.50263H4.40189L8.51292 17.8977L12.6703 8.50073H14.9593Z"
                          fill="#C4C4C4"
                        />
                        <path
                          d="M15.3809 8.50073H17.6757V20.6774H15.3809V8.50073Z"
                          fill="#C4C4C4"
                        />
                        <path
                          d="M31.3383 8.50073L25.9553 20.6774H23.6971L18.293 8.50073H20.777L24.888 17.8958L29.0357 8.50073H31.3383Z"
                          fill="#C4C4C4"
                        />
                        <path
                          d="M39.3853 17.8597H33.2091L31.9913 20.6794H29.625L35.1855 8.50269H37.4437L43.0215 20.6794H40.6206L39.3853 17.8597ZM38.6268 16.0865L36.2972 10.7592L33.9811 16.0865H38.6268Z"
                          fill="#C4C4C4"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Button to actions */}
        <div className="mt-[35px] w-full flex items-center justify-start gap-6">
          <div onClick={() => onPrev()}>
            <div className="md:hidden flex">
              <ArrowButton text="back" isRight={false} />
            </div>
            <div className="md:flex hidden">
              <ArrowButton text="" isRight={false} />
            </div>
          </div>

          {complimentaryNoCost ? (
            <div
              onClick={async () => {
                if (
                  moment(new Date()).diff(moment(contact?.dob), 'years') < 18 &&
                  vivaPayApprove
                ) {
                  await handleNextUnder18();
                } else if (
                  moment(new Date()).diff(moment(contact?.dob), 'years') >=
                    18 &&
                  vivaPayApprove
                ) {
                  await handleNextOver18();
                }
              }}
              className="flex-auto justify-end flex"
            >
              {!vivaPayApprove && (
                <div
                  className=" z-10 h-[86px] w-[220px] absolute right-0"
                  onClick={() => {
                    toastError('Please accept the Terms and Conditions.');
                  }}
                ></div>
              )}
              <div className="md:hidden flex">
                <ArrowButton text="signup" isRight={true} />
              </div>
              <div className="md:flex hidden">
                <ArrowButton text="pay now" isRight={true} />
              </div>
            </div>
          ) : (
            <div className="mt-8 z-10 relative">
              {!vivaPayApprove && (
                <div
                  className=" z-10 h-[86px] w-[220px] absolute right-0"
                  onClick={() => {
                    toastError('Please accept the Terms and Conditions.');
                  }}
                ></div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Payment;
