import React, { useState, createContext, useEffect, useRef } from 'react';
import { Amplify, Auth, Storage } from 'aws-amplify';
import { ApolloLink } from 'apollo-link';
import { createAuthLink } from 'aws-appsync-auth-link';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from '@apollo/client/link/context';
import { navigate } from 'gatsby';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { getMember } from './mutation';

const AuthContext = createContext<any>(null);
interface iProps {
  children: React.ReactNode;
}
const AuthProvider = ({ children }: iProps) => {
  const [userInfo, setUserInfo] = useState();
  const [refreshClient, setRefresh] = useState(false);
  const [refreshData, setRefreshData] = useState(false);

  const [client, setClient] = useState<any>(null);

  const Init = async () => {
    const getToken = async () => {
      try {
        const jwtToken = (await Auth.currentSession())
          .getIdToken()
          .getJwtToken();
        return jwtToken;
      } catch (e) {
        return '';
      }
    };
    const token = await getToken();

    Amplify.configure({
      region: `${process.env.AWS_REGION}`,
      identityPoolId: `${process.env.AWS_COGNITO_IDENTITY_POOL_ID}`,
      aws_project_region: `${process.env.AWS_REGION}`,
      aws_appsync_graphqlEndpoint: `${process.env.AWS_ENDPOINT}`,
      aws_appsync_region: `${process.env.AWS_REGION}`,
      aws_appsync_authenticationType: token
        ? `${process.env.AWS_SIGNIN_AUTH_TYPE}`
        : `${process.env.AWS_AUTH_TYPE}`,
      aws_user_pools_id: `${process.env.AWS_USER_POOLS_ID}`,
      aws_cognito_identity_pool_id: `${process.env.AWS_COGNITO_IDENTITY_POOL_ID}`,
      aws_user_pools_web_client_id: `${process.env.AWS_USER_POOLS_WEB_CLIENT_ID}`,
      aws_user_files_s3_bucket: `${process.env.AWS_S3_BUCKET_NAME}`,
      aws_user_files_s3_bucket_region: `${process.env.AWS_S3_BUCKET_REGION}`,
    });

    const authLink = createAuthLink({
      url: `${process.env.AWS_ENDPOINT}`,
      region: `${process.env.AWS_REGION}`,
      auth: {
        //@ts-ignore
        type: token
          ? `${process.env.AWS_SIGNIN_AUTH_TYPE}`
          : `${process.env.AWS_AUTH_TYPE}`,
        credentials: () => ({
          accessKeyId: `${process.env.ACCESS_KEY_ID}`,
          secretAccessKey: `${process.env.SECRET_ACCESS_KEY}`,
        }),
      },
    });

    const requestMiddleware = setContext(async (_, { headers }) => {
      const token = await getToken();
      return {
        headers: {
          ...headers,
          authorization: token ? `${token}` : '',
        },
      };
    });

    const responseMiddleWare = new ApolloLink((operation, forward) => {
      return forward(operation).map((response) => {
        return response;
      });
    });

    const client = async () => {
      const token = await getToken();
      if (token) {
        navigate('/dashboard');
        return new ApolloClient({
          //@ts-ignore
          link: ApolloLink.from([
            //@ts-ignore
            requestMiddleware,
            responseMiddleWare,
            createHttpLink({ uri: process.env.AWS_ENDPOINT }),
          ]),
          cache: new InMemoryCache(),
        });
      } else {
        setUserInfo(undefined);
        setUserInfoMemberShip(undefined);
        return new ApolloClient({
          //@ts-ignore
          link: ApolloLink.from([
            authLink,
            createHttpLink({ uri: process.env.AWS_ENDPOINT }),
          ]),
          cache: new InMemoryCache(),
        });
      }
    };
    const current = await client();
    setClient(current);
  };
  useEffect(() => {
    Init();
  }, []);
  const [userInfoMemberShip, setUserInfoMemberShip] = useState();
  const getUserInfo = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const res = await client.query({
        fetchPolicy: 'no-cache',
        query: getMember,
        variables: {
          memberId: user?.attributes['custom:memberId'],
        },
      });
      const data = res.data.getMember;
      const imgUrl = data?.imageUrl
        ? await Storage.get(data.imageUrl)
        : '/icons/Dashboard/profile.svg';
      setUserInfo({ ...data, imageUrl: imgUrl });
      localStorage.setItem(
        'currentUserDetail',
        JSON.stringify(res.data.getMember)
      );
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        Init,
        client,
        refreshClient,
        setRefresh,
        refreshData,
        setRefreshData,
        userInfo,
        setUserInfo,
        userInfoMemberShip,
        setUserInfoMemberShip,
        getUserInfo,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
export { AuthContext, AuthProvider };
