import React, { useState, useEffect, useContext, useMemo } from 'react';
// import arrowLeftShort from "../../../assets/images/icons/arrowLeftShort.svg";
import TableBase from '@/common/tables/TableBase';
import DashBoardLayout from '@/components/layout/DashBoardLayout';
import InputBase from '@/common/inputs/InputBase';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/material_green.css';
import CheckboxActive from '@/common/buttons/CheckboxActive';
import { Link } from 'gatsby';
// import { jsFileDownloader } from "js-client-file-downloader";
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import plusLogoBlack from '@/assets/images/plus-logo-black.png';
import { AuthContext } from '@/api/auth';
import {
  gql,
  useApolloClient,
  useLazyQuery,
  useMutation,
  useQuery,
} from '@apollo/client';
import {
  LIST_TRANSACTION_BY_MEMBERID,
  addMemberPaymentInformation,
  createMemberInvoiceYearly,
  getMemberReceiptByMemberId,
  getMembershipById,
  receiptDownload,
  updatePaymentInformation,
} from '@/api/mutation';
import { toastError, toastSuccess } from '@/common/toasts/toast';
import { Auth, Storage } from 'aws-amplify';
import PrimaryOutlinedButton from '@/common/buttons/PrimaryOutlinedButton';
import ModalBase from '@/common/modals/ModalBase';
import Modal from 'react-responsive-modal';
import moment from 'moment';
import PrimaryButton from '@/common/buttons/PrimaryButton';
import { format, utcToZonedTime } from 'date-fns-tz';

export type UserReceipt = {
  transactionId: string;
  debitDate: string;
  pdfUrl: string;
  amount: string;
};
type tableData = {
  receiptUrl: string;
  amount: string;
  date: string;
  'order Id': string;
};
// receiptUrl: process.env.BUCKET_LINK + receipt.pdfUrl,
// // id: i.id,
// date: receipt.debitDate.split('-').reverse().join('/'),
// 'order Id': receipt.transactionId,
// amount: '$' + (receipt?.amount ?? 0),
// // data: { ...i },
// // currentItem: { ...item },
function Biling(props) {
  const userInfo = useContext(AuthContext).userInfo;
  const getUserInfo = useContext(AuthContext).getUserInfo;

  const [startDate, setStartDate] = useState<string>('');

  const [typeOfPayment, setTypeOfPayment] = useState('card');

  const [dataBodyPast, setDataBodyPast] = useState([]);
  const dataHeadPast = [
    'date',
    'order Id',
    'amount',
    {
      label: 'action',
      elm: (i: tableData) => {
        const downPDF = async () => {
          try {
            const link = document.createElement('a');
            link.href = i.receiptUrl;
            link.target = '_blank';
            link.download = 'receipt-' + i.date;
            link.click();
          } catch (e) {
            toastError(
              'There is some error when grab data about your billing please try again later'
            );
            console.log(e.message);
          }
        };
        return (
          <div
            onClick={() => {
              downPDF();
            }}
          >
            {' '}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="icon icon-tabler icon-tabler-arrow-bar-to-down"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke="currentColor"
              fill="none"
              strokeLinecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" />
              <line x1="4" y1="20" x2="20" y2="20" />
              <line x1="12" y1="14" x2="12" y2="4" />
              <line x1="12" y1="14" x2="16" y2="10" />
              <line x1="12" y1="14" x2="8" y2="10" />
            </svg>
          </div>
        );
      },
    },
  ];
  const [getReceipt] = useLazyQuery(getMemberReceiptByMemberId);
  useEffect(() => {
    if (props?.userInfo?.memberId) {
      const vars = {
        memberId: props?.userInfo?.memberId,
        filter: undefined,
      };

      if (startDate && startDate[0] && startDate[1]) {
        const dateRange = [
          moment(startDate[0]).format('yyyy-MM-DD'),
          moment(startDate[1]).format('yyyy-MM-DD'),
        ];
        vars.filter = {
          debitDate: {
            between: dateRange,
          },
        };
      }
      getReceipt({
        fetchPolicy: 'no-cache',
        variables: { ...vars, sortDirection: 'ASC' },
        onCompleted: (data) => {
          if (data && data.getMemberReceiptByMemberId.items)
            // console.log(data);

            setDataBodyPast(
              data.getMemberReceiptByMemberId.items
                .map((receipt: UserReceipt) => {
                  return {
                    receiptUrl: process.env.BUCKET_LINK + receipt.pdfUrl,
                    // id: i.id,
                    date: receipt.debitDate.split('-').reverse().join('/'),
                    'order Id': receipt.transactionId,
                    amount: '$' + (receipt?.amount ?? 0),
                    // data: { ...i },
                    // currentItem: { ...item },
                  };
                })
                ?.reverse()
            );
        },
      });
    }
  }, [props.userInfo, startDate]);

  const openVivaPayModal = () => {
    setModalAddPayment(true);
  };
  const [cardType, setCardType] = useState('');
  const [modalVivaPayIsOpen, setIsOpenVivaPay] = useState(false);
  const [loading, setLoading] = useState(false);
  const [updatePaymentInfo, { loading: updatePaymentLoading }] = useMutation(
    updatePaymentInformation
  );
  const [cardRequestId, setCardRequestId] = useState('');

  async function openModalVivaPay() {
    const user = await Auth.currentAuthenticatedUser();
    setIsOpenVivaPay(true);
    setLoading(true);
    try {
      const data = await updatePaymentInfo({
        variables: {
          memberId: user.attributes['custom:memberId'],
          requestApplication: 'MEMBER_PORTAL',
          action: 'CREATE_CARD_TOKEN',
          // requestApplication: 'MEMBER_PORTAL',
          // locationId: 'f5403e3b-4dcc-4611-9b60-a639b80ddf11',
        },
      });
      if (data?.data.updatePaymentInformation.cardRequestId) {
        setLoading(false);
        setCardRequestId(data.data?.updatePaymentInformation.cardRequestId);
      }
    } catch (e) {
      toastError(e.message);
      setLoading(false);
    } finally {
      setIsEdit('');
      setLoading(false);
    }
  }

  const [modalAddPaymentInfo, setModalAddPayment] = useState(false);
  const iframeSrc = `${process.env.VIVAPAY_IFRAME}/${cardRequestId}`;
  const iframeStyle = {
    width: '100%',
    height: '100%',
    border: 'none',
    outline: 'none',
    borderRadius: '10px',
    overflow: 'hidden',
  };
  const [futureOption, setFutureOption] = useState('oldCardAsDefault');
  const [paymentInformation, setPaymentInformation] = useState('');
  const [creditCard, setCreditCard] = useState(false);

  async function closeModalVivaPay(isBank: boolean) {
    setIsOpenVivaPay(false);
    props.setLoading(true);

    try {
      const user = await Auth.currentAuthenticatedUser();
      let variables = {
        action: 'ADD_CARD',
        creditCard: { cardRequestId: cardRequestId },
        memberId: user.attributes['custom:memberId'],
        requestApplication: 'MEMBER_PORTAL',
        bankAccount: null,
      };
      if (isBank) {
        variables.action = 'ADD_BANK';
        variables.creditCard = undefined;
        variables.bankAccount = {
          accountName: paymentInfoBank.holderName,
          accountNumber: paymentInfoBank.accountNumber,
          bsb: paymentInfoBank.bsb,
        };
      }
      const data = await updatePaymentInfo({
        variables: {
          ...variables,
          // action: 'ADD_CARD',
          // memberId: user.attributes['custom:memberId'],
          // creditCard: { cardRequestId: cardRequestId },

          // requestApplication: 'MEMBER_PORTAL',
          // locationId: changeMembershipData.locationId,
        },
      });
      setPaymentInformation(data.data?.updatePaymentInformation.body);
      if (!isBank) setCreditCard(true);
      // makeApiRequest().then((res) => {
      if (
        data.data?.updatePaymentInformation.body?.accountNumber ||
        data.data?.updatePaymentInformation.body?.cardNumber
      ) {
        toastSuccess('Save card successfully');
        setCardRequestId('');
        if (isBank) setModalAddPayment(false);
        getUserInfo();
      } else {
        toastError('Save card fail');
        setCardRequestId('');
      }
      // });
    } catch (e) {
      toastError(e.message);
    } finally {
      props.setLoading(false);
      setIsEdit('');
    }
  }
  const [paymentInfoBank, setPaymentInfoBank] = useState({
    holderName: '',
    accountNumber: '',
    bsb: '',
  });
  const setDataBank = (e) => {
    setPaymentInfoBank({ ...paymentInfoBank, [e.target.name]: e.target.value });
  };
  // useEffect(() => {
  //   if (!modalAddPaymentInfo) {
  //     getUserInfo();
  //   }
  // }, [modalAddPaymentInfo]);
  // console.log(userInfo);
  const [userCard, setUserCard] = useState({});
  const [userBank, setUserBank] = useState({});
  const [disabledBank, setDisabledBank] = useState(false);
  useEffect(() => {
    setBankInfo();
  }, [userInfo]);
  const [primaryPayment, setPrimary] = useState('');
  const setBankInfo = () => {
    const userPayment = userInfo?.paymentDetails?.items.filter(
      (i) =>
        i.primary &&
        i?.vivaPaymentInformationId &&
        i?.vivaPaymentInformationId !== ''
    );

    if (userPayment?.length) {
      if (userPayment[0].paymentType === 'CREDIT_CARD') {
        setPrimary('CREDIT_CARD');
        setTypeOfPayment('card');
        setUserCard(userPayment[0]);
        const sortedCard = userInfo?.paymentDetails?.items
          .filter(
            (i) =>
              i.paymentType !== 'CREDIT_CARD' &&
              i?.vivaPaymentInformationId &&
              i?.vivaPaymentInformationId !== ''
          )
          .sort(
            (a, b) =>
              new Date(a.createdAt).valueOf() - new Date(b.createdAt).valueOf()
          );
        setUserBank(sortedCard[0]);
        if (sortedCard.length) {
          setDisabledBank(true);
        }
      } else {
        setPrimary('BANK_ACCOUNT');
        setTypeOfPayment('bank');
        setUserBank(userPayment[0]);
        setDisabledBank(true);
        const sortedCard = userInfo?.paymentDetails?.items
          .filter(
            (i) =>
              i.paymentType === 'CREDIT_CARD' &&
              i?.vivaPaymentInformationId &&
              i?.vivaPaymentInformationId !== ''
          )
          .sort(
            (a, b) =>
              new Date(a.createdAt).valueOf() - new Date(b.createdAt).valueOf()
          )
          .reverse();
        setUserCard(sortedCard[0]);
      }
    }
  };
  const [updateInfo, setUpdateInfo] = useState<any>({});
  const [bankError, setBankError] = useState({
    bsb: false,
    accountName: false,
    accountNumber: false,
  });
  const followSteps = [
    'Accept Terms: Check the box to agree to the terms.',
    'Update: Click the Update button.',
    'Enter Details: In the Viva Pay modal, input your new card details.',
    'Save: Click Save Card to securely update your information.',
  ];
  const updateBank = async () => {
    try {
      props.setLoading(true);
      if (userInfo?.state !== 'NZ') {
        const isBankError = { ...bankError };
        if (
          updateInfo?.accountNumber?.length < 5 ||
          updateInfo?.accountNumber?.length > 9 ||
          isNaN(updateInfo?.accountNumber)
        ) {
          isBankError.accountNumber = true;
          // throw new Error('Invalid format please enter valid account number !');
        }
        if (
          !updateInfo?.accountName ||
          updateInfo?.accountName === '' ||
          updateInfo?.accountName
            ?.split('')
            .filter((i) => i != ' ')
            .some((i) => !isNaN(i * 1))
        ) {
          if (
            updateInfo?.accountName
              ?.split('')
              .filter((i) => i != ' ')
              .some((i) => !isNaN(i * 1))
          ) {
            isBankError.accountName = true;

            // throw new Error('Invalid format please enter valid account name !');
          }
          isBankError.accountName = true;
          // throw new Error('Invalid format please enter valid account name !');
        }
        if (
          updateInfo?.bsb?.length < 6 ||
          updateInfo?.bsb?.length > 9 ||
          isNaN(updateInfo?.bsb)
        ) {
          isBankError.bsb = true;
          // throw new Error('Invalid format please enter valid bsb number !');
        }
        setBankError(isBankError);
        if (Object.values(isBankError).some((i) => i)) {
          throw new Error(
            'Invalid bank information please check again and correct the information.'
          );
        }
      }
      const user = await Auth.currentAuthenticatedUser();
      let variables = {
        action: 'ADD_CARD',
        memberId: user.attributes['custom:memberId'],
        requestApplication: 'MEMBER_PORTAL',
        bankAccount: {},
      };
      variables.action = 'ADD_BANK';
      variables.bankAccount = {
        accountName: updateInfo?.accountName,
        accountNumber: updateInfo?.accountNumber,
        bsb: userInfo?.state === 'NZ' ? '000000' : updateInfo?.bsb,
      };
      const data = await updatePaymentInfo({
        variables: {
          ...variables,
        },
      });
      if (data?.data?.updatePaymentInformation.message) {
        throw new Error('Fail to add card!');
      }
      toastSuccess('Update successfully!');
      setDisabledBank(true);
      setUserBank({ ...variables.bankAccount });
      getUserInfo();
      setIsEdit('');
      props?.refresh();
      // client.refetchQueries({
      //   include: ['getMember'],
      // });
    } catch (e) {
      toastError(e.message);
    } finally {
      props.setLoading(false);
    }
  };
  const setUpdatedBank = (e) => {
    setBankError({ ...bankError, [e.target.name]: false });
    setUpdateInfo({ ...updateInfo, [e.target.name]: e.target.value });
  };
  const [acceptTerm, setAcceptTerm] = useState(false);
  const [creditType, setType] = useState();
  const confirmChange = (v) => {
    setAcceptTerm(v);
  };
  const [isEdit, setIsEdit] = useState('');
  const [financialYear, setFinancialYear] = useState({
    startDate: '',
    endDate: '',
  });

  const [generating, setGenerating] = useState(false);
  const [createMemberInvoiceYearlyApi] = useMutation(createMemberInvoiceYearly);
  const createMemberInVoice = async () => {
    try {
      setGenerating(true);
      const data = await createMemberInvoiceYearlyApi({
        variables: {
          operationName: 'GENERATE_INVOICE_YEARLY',
          memberIds: [props?.userInfo?.memberId],
          dateFrom: format(
            utcToZonedTime(
              new Date(financialYear.startDate),
              'Australia/sydney'
            ),
            'yyyy-MM-dd'
          ),
          dateTo: format(
            utcToZonedTime(new Date(financialYear.endDate), 'Australia/sydney'),
            'yyyy-MM-dd'
          ),
        },
      });

      const link = document.createElement('a');
      link.href = process.env.BUCKET_LINK + data.data.createMemberInvoiceYearly;
      link.download =
        data.data.createMemberInvoiceYearly.split('/')[
          data.data.createMemberInvoiceYearly.split('/').length - 1
        ];
      link.target = '_blank';

      link.click();
    } catch (e) {
      console.log(e);
      toastError(
        'There is some error when grab data about your billing please try again later'
      );
      console.log(e.message);
    } finally {
      setGenerating(false);
    }
  };
  return (
    <>
      {modalVivaPayIsOpen && (
        <div>
          <Modal
            open={cardType === 'differentCard'}
            onClose={() => closeModalVivaPay()}
            center
            styles={{
              overlay: {
                background: 'rgba(45, 45, 35, 0.4)',
              },
              modal: {
                height: '62%',
              },
            }}
          >
            {loading ? (
              <div className="z-50 absolute top-[50%] left-[30%] ">
                <div className="row examples m-auto ">
                  <div className="  ">
                    <div className=" relative " data-title="dot-pulse">
                      <div className="stage w-[200px]">
                        <div className="dot-pulse"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <iframe
                id="pay-with-vivapay"
                title="pay with vivapay"
                src={iframeSrc}
                style={iframeStyle}
              />
            )}
          </Modal>
        </div>
      )}
      {modalAddPaymentInfo && (
        <ModalBase
          onClickOutSide={() => {}}
          loading={updatePaymentLoading}
          closeModal={setModalAddPayment}
        >
          <div className="text-white px-[33px] py-[33px]  ">
            <p className=" pb-6 text-[35px] mr-auto ml-auto text-center w-[482px] ">
              ADD PAYMENT INFORMATION
            </p>
            <div className="grid grid-cols-3 gap-[24px]">
              <p className="col-span-3">MEMBER DETAILS</p>
              <div>
                <p className="text-[#bfbfbf] mb-[5px]  text-sm leading-[90%]">
                  Member No:
                </p>
                <p className="text-white h-[18px] border-b border-b-white text-sm leading-[90%]">
                  {userInfo?.aliasMemberId}
                </p>
              </div>
              <div>
                <p className="text-[#bfbfbf] mb-[5px]  text-sm leading-[90%]">
                  Member Name:
                </p>
                <p className="text-white h-[18px] border-b border-b-white text-sm leading-[90%]">
                  {userInfo?.givenName} {userInfo?.surname}
                </p>
              </div>
              <div>
                <p className="text-[#bfbfbf] mb-[5px]  text-sm leading-[90%]">
                  Date Of Birth:
                </p>
                <p className="text-white border-b h-[18px] border-b-white text-sm leading-[90%]">
                  {userInfo?.dob?.split('-').reverse()?.join('/')}
                </p>
              </div>
              <p className="col-span-3">Add Payments Information</p>
              <div
                className="justify-start flex cursor-pointer"
                onClick={() => {
                  setCardType('differentCard');
                  openModalVivaPay();
                }}
              >
                <input
                  className="mr-[10px]"
                  type="radio"
                  checked={cardType === 'differentCard'}
                />
                <p>Credit card</p>
              </div>
              {cardType === 'differentCard' &&
                paymentInformation?.cardNumber && (
                  <div className="grid grid-cols-3 col-span-3 gap-x-7 gap-y-7">
                    <div className="col-span-1">
                      <p className="text-[#bfbfbf] mb-[5px]  text-sm leading-[90%]">
                        Type:
                      </p>
                      <p className="text-white border-b border-b-white text-sm h-[18px] leading-[90%]">
                        {paymentInformation?.cardType
                          ? paymentInformation?.cardType
                          : paymentInformation?.accountName}
                      </p>
                    </div>

                    <div className="col-span-1">
                      <p className="text-[#bfbfbf] mb-[5px]  text-sm leading-[90%]">
                        Card:
                      </p>
                      <p className="text-white border-b border-b-white  h-[18px] text-sm leading-[90%]">
                        {paymentInformation?.cardNumber}
                      </p>
                    </div>
                    <div className="col-span-1">
                      <p className="text-[#bfbfbf] mb-[5px]  text-sm leading-[90%]">
                        Expiry:
                      </p>
                      <p className="text-white border-b border-b-white h-[18px] text-sm leading-[90%]">
                        {paymentInformation?.cardExpiryDate
                          ? paymentInformation?.cardExpiryDate
                          : paymentInformation?.accountNumber}
                      </p>
                    </div>
                  </div>
                )}
              {creditCard != true && (
                <div className="col-span-3">
                  {/* {Object.keys(cardPrimary).length !== 0 &&
                    cardType !== 'cardOnFile' && (
                      <label className="cursor-pointer mt-[16px] flex">
                        <input
                          className="mr-[10px]"
                          type="radio"
                          checked={futureOption === 'newCardAsDefault'}
                          onClick={() => setFutureOption('newCardAsDefault')}
                        />
                        <p className="tracking-[0.5px]">Bank account</p>
                      </label>
                    )} */}
                  {creditType && creditType !== 'UPDATE' && (
                    <label className="cursor-pointer mt-[16px] flex">
                      <input
                        className="mr-[10px]"
                        type="radio"
                        checked={futureOption === 'bankAccount'}
                        onClick={() => setFutureOption('bankAccount')}
                      />
                      <p className="tracking-[0.5px]">Bank account</p>
                    </label>
                  )}
                </div>
              )}
              {futureOption === 'bankAccount' && (
                <div className=" col-span-3 grid w-full md:grid-cols-1 grid-cols-2 gap-x-9 gap-y-[13px]">
                  <InputBase
                    holder="Account name*"
                    nameInput="holderName"
                    style="lg:w-full"
                    isRequired={true}
                    onchange={(e) => setDataBank(e)}
                  />
                  <InputBase
                    holder="Account number*"
                    style="lg:w-full"
                    nameInput="accountNumber"
                    isRequired={true}
                    onchange={(e) => setDataBank(e)}
                  />
                  <InputBase
                    holder="BSB*"
                    style="lg:w-full"
                    nameInput="bsb"
                    isRequired={true}
                    onchange={(e) => setDataBank(e)}
                  />
                </div>
              )}
              <div className="col-span-3 flex items-center justify-center gap-6">
                {creditCard != true && (
                  <PrimaryOutlinedButton
                    onClick={() => {
                      closeModalVivaPay(true);
                      setType();
                    }}
                    className="rounded-md"
                  >
                    ADD PAYMENT
                  </PrimaryOutlinedButton>
                )}
                <PrimaryOutlinedButton
                  className="hover:bg-textError hover:text-white rounded-md hover:border-textError"
                  onClick={() => {
                    setModalAddPayment(false);
                  }}
                >
                  CANCEL
                </PrimaryOutlinedButton>
              </div>
            </div>
          </div>
        </ModalBase>
      )}
      <div className="text-white">
        {userInfo &&
        userInfo?.paymentDetails &&
        userInfo?.paymentDetails.items.length === 0 ? (
          <div className="mt-[38px]  pb-[32px] border border-gray rounded  relative">
            <div className="h-[57px] px-[27px]  border-b border-b-white text-white tracking-widest gotham text-sm  flex items-center uppercase">
              payment method
            </div>
            <div className="mt-[29px] mx-[27px] flex flex-row md:flex-col items-center md:items-start justify-between">
              <PrimaryOutlinedButton
                onClick={() => {
                  openVivaPayModal();
                }}
                className=""
              >
                Update Your Payment Information
              </PrimaryOutlinedButton>
            </div>

            {/* <div className="mt-[37px] flex items-center pl-[27px] gap-[14px]">
                <div className="w-max">
                  <CheckboxActive isDashboard={true} />
                </div>

                <p className="text-white gotham font-normal w-[542px] text-[11px]">
                  I understand that these new details will replace the account
                  details in my Direct Debit Request Service agreement with Viva
                  Leisure Group.
                </p>
              </div> */}
            {/* <PrimaryOutlinedButton
                onClick={() => console.log('update')}
                className="flex uppercase ml-[27px] rounded-[5px] text-base font-bold leading-[160%] text-white min-h-[34px] h-[34px] w-[152px] mt-3 justify-center items-center"
              >
                Update
              </PrimaryOutlinedButton> */}
          </div>
        ) : (
          <div className="mt-[38px]  pb-[32px]  border border-gray rounded  relative">
            <div className="h-[57px] px-[27px] flex justify-between border-b border-b-white text-white tracking-widest gotham text-sm items-center uppercase">
              payment method
              {(isEdit === '' || typeOfPayment !== isEdit) && (
                <img
                  onClick={() => {
                    setIsEdit(typeOfPayment === 'card' ? 'card' : 'bank');
                    if (typeOfPayment === 'bank') {
                      setUpdateInfo({ ...userBank });
                    }
                  }}
                  className="block w-5 h-5"
                  src="/icons/pencil-white.png"
                  alt="edit"
                />
              )}
            </div>
            <div className="mt-[29px] flex mx-[27px] flex-row lg:flex-col items-center lg:items-start   justify-between">
              <div className="flex items-center lg:w-auto lg:pl-[27px] w-full">
                <button
                  onClick={() => {
                    setTypeOfPayment('bank');
                    setIsEdit('');
                  }}
                  className={`relative uppercase h-[40px] rounded-l w-[150px] sm:w-[50vw] text-[11px] gotham font-bold  ${
                    typeOfPayment !== 'bank'
                      ? 'border border-solid border-white text-white'
                      : ' bg-primary  pt-[3px]'
                  } border-t border-l  border-r`}
                >
                  {primaryPayment === 'BANK_ACCOUNT' && (
                    <span className="absolute bottom-full left-0 text-xs inline-block px-1 border border-white rounded-t-[3px] bg-primary ">
                      primary
                    </span>
                  )}
                  {userInfo?.state === 'NZ' ? 'BANK CODE' : 'BANK ACCOUNT'}
                </button>
                <button
                  onClick={() => {
                    setTypeOfPayment('card');
                    setIsEdit('');
                  }}
                  className={`relative uppercase h-[40px] rounded-r w-[150px] sm:w-[50vw] text-[11px] gotham font-bold  ${
                    typeOfPayment !== 'card'
                      ? 'border border-solid border-white text-white'
                      : ' bg-primary pt-[3px]'
                  } border-t border-l  border-r`}
                >
                  {primaryPayment === 'CREDIT_CARD' && (
                    <span className="absolute bottom-full left-0 text-xs inline-block px-1 border border-white rounded-t-[3px] bg-primary ">
                      primary
                    </span>
                  )}
                  CREDIT CARD
                </button>
              </div>
            </div>
            {typeOfPayment === 'bank' && (
              <>
                {' '}
                <div className="grid grid-cols-2 lg:grid-cols-1 gap-y-[25px]  mt-[33px] px-[27px] ">
                  <InputBase
                    isDashboard={true}
                    holder={
                      userInfo?.state === 'NZ'
                        ? 'Account Holder Name*'
                        : 'Account name*'
                    }
                    style="lg:w-full"
                    disabled={isEdit !== 'bank'}
                    nameInput="accountName"
                    onchange={(e) => {
                      setUpdatedBank(e);
                    }}
                    value={
                      updateInfo?.accountName || updateInfo?.accountName === ''
                        ? updateInfo?.accountName
                        : userBank?.accountName ?? ''
                    }
                    errorText="Account name can't be empty"
                    isShowRequired={bankError.accountName}
                    isRequired={true}
                    positionMessage="bottom"
                    isShowRequireIcon={false}
                    errorMessageStart={true}
                  />
                  {/* <InputBase
                    isDashboard={true}
                    holder="Bank name*"
                    style="lg:w-full"
                    isRequired={true}
                    disabled={primaryPayment !== 'BANK_ACCOUNT'}

                    value={userBank?.cardHolderName ?? ''}
                  /> */}
                  {userInfo?.state !== 'NZ' && (
                    <InputBase
                      type="number"
                      isDashboard={true}
                      holder="BSB*"
                      style="lg:w-full"
                      disabled={isEdit !== 'bank'}
                      nameInput="bsb"
                      onchange={(e) => {
                        setUpdatedBank(e);
                      }}
                      value={
                        updateInfo?.bsb || updateInfo?.bsb === ''
                          ? updateInfo?.bsb
                          : userBank?.bsb ?? ''
                      }
                      errorText="BSB is invalid"
                      isShowRequired={bankError.bsb}
                      isRequired={true}
                      positionMessage="bottom"
                      isShowRequireIcon={false}
                      errorMessageStart={true}
                    />
                  )}
                  <InputBase
                    type="number"
                    isDashboard={true}
                    holder={
                      userInfo?.state === 'NZ'
                        ? 'Bank code*'
                        : 'Account number*'
                    }
                    style="lg:w-full"
                    disabled={isEdit !== 'bank'}
                    nameInput="accountNumber"
                    onchange={(e) => {
                      setUpdatedBank(e);
                    }}
                    value={
                      updateInfo?.accountNumber ||
                      updateInfo?.accountNumber === ''
                        ? updateInfo?.accountNumber
                        : userBank?.accountNumber ?? ''
                    }
                    errorText="Account number is invalid"
                    isShowRequired={bankError.accountNumber}
                    isRequired={true}
                    positionMessage="bottom"
                    isShowRequireIcon={false}
                    errorMessageStart={true}
                  />
                </div>
                {isEdit === 'bank' && (
                  <>
                    <div className="mt-[37px] flex items-center pl-[27px] gap-[14px]">
                      <div className="w-max">
                        <CheckboxActive
                          isDashboard={true}
                          onChecked={(e) => confirmChange(e)}
                        />
                      </div>

                      <p className="text-white gotham font-normal w-[542px] text-[11px]">
                        I understand that these new details will replace the
                        account details in my Direct Debit Request Service
                        agreement with Viva Leisure Group.
                      </p>
                    </div>
                    <button
                      disabled={!acceptTerm}
                      onClick={() => updateBank()}
                      className={`mt-[25px] border-solid white text-[11.2px] uppercase rounded border  transition-all ml-[27px] border-gray text-white w-[146px] h-[36px] ${
                        !acceptTerm
                          ? 'border-slate-500 text-slate-500'
                          : 'hover:bg-white hover:text-black '
                      }`}
                    >
                      update
                    </button>
                  </>
                )}
              </>
            )}
            {typeOfPayment === 'card' && (
              <>
                <div className="grid grid-cols-2 lg:grid-cols-1 gap-y-[14px]  mt-[33px] px-[27px] ">
                  <InputBase
                    disabled={true}
                    isDashboard={true}
                    holder="Name on the card"
                    style="lg:w-full"
                    isRequired={true}
                    value={userCard?.cardHolderName ?? ''}
                  />
                  <InputBase
                    disabled={true}
                    isDashboard={true}
                    holder="Card Number*"
                    style="lg:w-full"
                    isRequired={true}
                    value={userCard?.cardNumber ?? ''}
                  />
                  <InputBase
                    disabled={true}
                    isDashboard={true}
                    holder="Expiry Date*"
                    style="lg:w-full"
                    isRequired={true}
                    value={userCard?.cardExpiryDate ?? ''}
                  />
                  <InputBase
                    disabled={true}
                    isDashboard={true}
                    holder="CVV*"
                    style="lg:w-full"
                    isRequired={true}
                    value={userCard?.cardCvv ?? ''}
                  />
                </div>

                {isEdit === 'card' && (
                  <>
                    <div className="mt-[37px] flex items-center pl-[27px] gap-[14px]">
                      <div className="w-max">
                        <CheckboxActive
                          isDashboard={true}
                          onChecked={(e) => confirmChange(e)}
                        />
                      </div>

                      <p className="text-white gotham font-normal w-[542px] text-[12px]">
                        I understand that these new details will replace the
                        account details in my Direct Debit Request Service
                        agreement with Viva Leisure Group.
                      </p>
                    </div>
                    <button
                      disabled={!acceptTerm}
                      className={`mt-[25px] border-solid white text-[11.2px] uppercase rounded border  transition-all ml-[27px] border-gray text-white w-[146px] h-[36px] ${
                        !acceptTerm
                          ? 'border-slate-500 text-slate-500'
                          : ' hover:bg-white hover:text-black '
                      }`}
                      onClick={() => {
                        setType('UPDATE');
                        // openVivaPayModal();
                        setCardType('differentCard');
                        openModalVivaPay();
                      }}
                    >
                      update
                    </button>
                    <div className="mt-[30px]  text-gray items-center pl-[27px] gap-[14px]">
                      <p className="mb-[10px] text-[14px]">
                        Follow these steps to update your card information
                        securely:
                      </p>
                      {followSteps.map((item, index) => {
                        return (
                          <p className="text-[12px] mt-[5px] ">
                            {index + 1} {'. '}
                            {item}
                          </p>
                        );
                      })}
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        )}

        <div className="mt-[45px] sm:mt-[32px]  pb-[30px] sm:border-none sm:bg-transparent border-[rgba(255,255,255,0.4)] border-[1.5px] mb-[90px] rounded">
          <div className="h-[57px] font-bicyclette px-[27px] font-medium text-sm tracking-[1.4px] sm:border-primary sm:text-primary sm:px-0 sm:h-[28px] sm:text-[20px] sm:pb-[10px] sm:mx-6 border-b border-b-white text-white  flex items-center uppercase">
            Financial Year Tax Invoice
          </div>
          <div className="">
            <div className="grid grid-cols-3 px-6 gap-6 pt-6 items-center">
              <label>
                <div
                  className={`  border-b flex sm:h-[50px]  h-[49px] pb-[2px]  relative ${
                    financialYear.startDate !== ''
                      ? 'border-b-gray'
                      : 'border-b-gray'
                  } `}
                >
                  <div className="absolute billingFP  bottom-[6px] w-full">
                    <Flatpickr
                      // placeHolder=""
                      onChange={(dateObj, dateStr) => {
                        console.log(dateStr);
                        setFinancialYear({
                          ...financialYear,
                          startDate: dateObj[0],
                        });
                      }}
                      options={{
                        dateFormat: 'd/m/Y',
                        // mode: 'range',
                        disableMobile: 'true',
                        enableTime: false,
                        time_24hr: false,
                        allowInput: false,
                      }}
                    />
                  </div>
                  <label
                    className={`gotham text-[12px] absolute left-0 ${
                      financialYear.startDate !== ''
                        ? 'text-white top-[2px]'
                        : 'text-gray bottom-[14px]'
                    }`}
                  >
                    Start date
                  </label>
                  <div className="absolute  z-10 right-3 bottom-3">
                    {calendarIcon()}
                  </div>
                </div>
              </label>
              <label>
                <div
                  className={` border-b flex sm:h-[50px]  h-[49px] pb-[2px]  relative ${
                    financialYear.endDate !== ''
                      ? 'border-b-gray'
                      : 'border-b-gray'
                  } `}
                >
                  <div className="absolute billingFP  bottom-[6px] w-full">
                    <Flatpickr
                      // placeHolder=""
                      onChange={(dateObj, dateStr) => {
                        setFinancialYear({
                          ...financialYear,
                          endDate: dateObj[0],
                        });
                      }}
                      options={{
                        dateFormat: 'd/m/Y',
                        // mode: 'range',
                        disableMobile: 'true',
                        enableTime: false,
                        time_24hr: false,
                        allowInput: false,
                        minDate: financialYear.startDate,
                      }}
                    />
                  </div>
                  <label
                    className={`gotham text-[12px] absolute left-0 ${
                      financialYear.endDate !== ''
                        ? 'text-white top-[2px]'
                        : 'text-gray bottom-[14px]'
                    }`}
                  >
                    End date
                  </label>
                  <div className="absolute  z-10 right-3 bottom-3">
                    {calendarIcon()}
                  </div>
                </div>
              </label>

              <PrimaryButton
                onClick={() => {
                  if (
                    financialYear.startDate &&
                    financialYear.endDate &&
                    financialYear.startDate !== '' &&
                    financialYear.endDate !== ''
                  ) {
                    createMemberInVoice();
                  }
                }}
                disabled={
                  !Boolean(
                    financialYear.startDate &&
                      financialYear.endDate &&
                      financialYear.startDate !== '' &&
                      financialYear.endDate !== '' &&
                      generating === false
                  )
                }
                className={`w-fit h-fit py-2 px-3 rounded-md bg-transparent  border-[1px] border-solid self-end   ${
                  generating
                    ? ' border-white text-primary cursor-not-allowed'
                    : 'hover:!bg-primary hover:!border-primary border-white text-primary'
                }`}
              >
                Generate
              </PrimaryButton>
            </div>
          </div>
        </div>

        <div className="mt-[45px] sm:mt-[32px]  pb-[30px] sm:border-none sm:bg-transparent border-[rgba(255,255,255,0.4)] border-[1.5px] mb-[90px] rounded">
          <div className="h-[57px] font-bicyclette px-[27px] font-medium text-sm tracking-[1.4px] sm:border-primary sm:text-primary sm:px-0 sm:h-[28px] sm:text-[20px] sm:pb-[10px] sm:mx-6 border-b border-b-white text-white  flex items-center uppercase">
            purchase history
          </div>
          <div className="">
            <div className="mt-[34px] sm:mt-[20px] lg:flex-col  flex-row flex  items-center justify-between mx-[30.5px]">
              <label>
                <div
                  className={`w-[400px]  border-b flex sm:h-[50px]  h-[59px] pb-[2px]  relative ${
                    startDate !== '' ? 'border-b-gray' : 'border-b-gray'
                  } `}
                >
                  <div className="absolute billingFP  bottom-[6px] w-full">
                    <Flatpickr
                      // placeHolder=""
                      onChange={(dateObj, dateStr) => {
                        if (dateObj[0] && dateObj[1]) setStartDate(dateObj);
                      }}
                      options={{
                        dateFormat: 'd/m/Y',
                        mode: 'range',
                        disableMobile: 'true',
                        enableTime: false,
                        time_24hr: false,
                        allowInput: false,
                      }}
                    />
                  </div>
                  <label
                    className={`gotham text-[12px] absolute left-0 ${
                      startDate !== ''
                        ? 'text-white top-[2px]'
                        : 'text-gray bottom-[14px]'
                    }`}
                  >
                    Date range
                  </label>
                  <div className="absolute  z-10 right-3 bottom-3">
                    {calendarIcon()}
                  </div>
                </div>
              </label>
            </div>
            <div className="sm:hidden mx-[30.5px] mt-[35px]">
              <TableBase
                data="download"
                headerCells={dataHeadPast}
                bodyCells={dataBodyPast}
              />
            </div>
            <div className="sm:block hidden mx-6 mt-[36px]">
              {dataBodyPast?.map((i, index: number) => (
                <div
                  key={index}
                  className="grid items-center grid-cols-2 border-b border-textSecondary py-[12px]"
                >
                  <div className="text-[16px] font-bold h-[26px] flex items-center">
                    {i.date.split('-').reverse().join('/')}
                  </div>
                  <div className="text-end font-bold h-[26px]flex items-center">
                    {i.amount}
                  </div>
                  <div className="col-span-2 py-[5px] flex items-center min-h-[26px] text-textSecondary">
                    {i.item}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
const calendarIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="19"
    viewBox="0 0 20 19"
    fill="none"
  >
    <g clipPath="url(#clip0_1_1209)">
      <path
        d="M4.09005 5.14832H4.09376C4.70159 5.14832 5.19434 4.65957 5.19434 4.05669L5.19434 1.08795C5.19434 0.485065 4.70159 -0.00366497 4.09376 -0.00366497H4.09005C3.48222 -0.00366497 2.98947 0.485065 2.98947 1.08795L2.98947 4.05669C2.98947 4.65957 3.48222 5.14832 4.09005 5.14832Z"
        fill="white"
      />
      <path
        d="M16.0813 5.14832H16.085C16.6928 5.14832 17.1855 4.65957 17.1855 4.05669V1.08795C17.1855 0.485065 16.6928 -0.00366497 16.085 -0.00366497H16.0813C15.4734 -0.00366497 14.9807 0.485065 14.9807 1.08795V4.05669C14.9807 4.65957 15.4734 5.14832 16.0813 5.14832Z"
        fill="white"
      />
      <path
        d="M1.52086 18.9999H18.4906C18.8923 18.9989 19.2772 18.84 19.5608 18.5579C19.8445 18.2758 20.0039 17.8937 20.0039 17.4953V3.57717C20.0039 3.17875 19.8445 2.79659 19.5608 2.51452C19.2772 2.23245 18.8923 2.07349 18.4906 2.07251H1.52086C1.11917 2.07349 0.734266 2.23245 0.450575 2.51452C0.166884 2.79659 0.00756715 3.17875 0.00756836 3.57717V17.4953C0.00756715 17.8937 0.166884 18.2758 0.450575 18.5579C0.734266 18.84 1.11917 18.9989 1.52086 18.9999V18.9999ZM1.72908 6.82989H18.2823V16.979H1.72164L1.72908 6.82989Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_1_1209">
        <rect width="20" height="19" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default Biling;
